
$cheese-height: 3;
$cheese-space: 4;


%burger-item {
	content: '';
	display: block;
	height: em($cheese-height);
	width: 100%;
	background-color: $color-black;
	position: absolute;
	border-radius: rem(2);
}
.header {
	&:not(.menu-active) {
		.burger-menu {
			&:hover {
				.burger {
					&:before {
						//@include animation(burger-up .2s ease-in 2);
					}
					&:after {
						//@include animation(burger-up .2s ease-in 2 alternate-reverse);
					}
				}
			}
		}
	}
}

.burger-menu { // TODO
	width: em(26);
	cursor: pointer;
	position: absolute;
	top: em(29);
	right: em(5);
	@include mq($from: screen-sm) {
		display: none;
	}
	// &:hover {
	// 	.burger {
	// 		&:before {
	// 			//transform: translateY(-2px);
	// 			@include animation(burger-up .2s ease-in 2);
	// 		}
	// 		&:after {
	// 			//transform: translateY(2px);
	// 			@include animation(burger-up .2s ease-in 2 alternate-reverse);
	// 		}
	// 	}
	// }
	.burger {
		display: block;
		position: relative;
		padding: em($cheese-space + $cheese-height) 100% em($cheese-space + $cheese-height*2) 0;
		&>.burger__cheese {
			@extend %burger-item;
			transition: opacity .2s, transform .2s;
			.menu-active & {
				opacity: 0;
			}
		}
		&:before {
			transition: transform .2s;
			top: 0;
			@extend %burger-item;
			.menu-active & {
				transform: rotate(45deg);
				top: 5px;
			}
		}
		&:after {
			transition: transform .2s;
			bottom: 0;
			@extend %burger-item;
			.menu-active & {
				transform: rotate(-45deg);
				top: 5px;
			}
		}
	}
	.burger__caption {
		font-size: em(11);
		text-align: center;
		color: $color-black;
	}
}
@keyframes burger-up {
    0% {
		transform: rotate(3deg);
    }
    50% {
		transform: rotate(-3deg);
    }
    100% {
		transform: rotate(0deg);
    }
}
