// Colors
//
// Colors available
//
// Markup: <span class="styleguide-color {{modifier_class}}"</span>
//
//	.c--black   			- HEX:333333
//	.c--green		   		- HEX:005448
//	.c--blue   				- HEX:4981A3
//	.c--blue-dark   		- HEX:335057
//	.c--blue-darker   		- HEX:1c2a2e
//	.c--grey   				- HEX:e3e3e3
//	.c--grey-light   		- HEX:f8f8f8
//	.c--grey-dark   		- HEX:767676
//	.c--yellow		   		- HEX:ffda00
//	.c--yellow-light   		- HEX:fceda6
//	.c--red		   			- HEX:f32f00
//	.c--red-light   		- HEX:fccfc4
//	.c--color-bg   			- HEX:f8f8f8 ~ grey-light
//	.c--color-border   		- HEX:e3e3e3 ~ grey
//	.c--color-link   			- HEX:4981A3 ~ blue
//
// Style guide 1.6

// WHITE
$color-white: #fff;

// BLACK
$color-black: #333333;

// GREEN
$color-green: #005448;

// BLUE
$color-blue: #4981A3;
$color-blue-dark: #335057;
$color-blue-darker: #1c2a2e;

// GREY
$color-grey: #e3e3e3;
$color-grey-light: #f8f8f8;
$color-grey-dark: #767676;

// YELLOW
$color-yellow: #ffda00;
$color-yellow-light: #fceda6;

// RED
$color-red: #f32f00;
$color-red-light: #fccfc4;


//Semantic shorthands
//borders
$color-border: $color-grey;
//$color-border-light: $color-grey-light;

//backgrounds
$color-bg: $color-grey-light;
//$color-bg: black;
$color-bg-dark: #eee;

//links
$color-link: $color-blue;
$color-link-hover: $color-blue-dark;


//Colors only for styleguide
%styleguide-color {
	display: inline-block;
	width: 200px;
	height: 50px;
	border-radius: 5px;
}
.c--white { 
	background-color: $color-white; @extend %styleguide-color;
}
.c--black {
	background-color: $color-black; @extend %styleguide-color;
}
.c--green {
	background-color: $color-green; @extend %styleguide-color;
}
.c--blue {
	background-color: $color-blue; @extend %styleguide-color;
}
.c--blue-dark {
	background-color: $color-blue-dark; @extend %styleguide-color;
}
.c--blue-darker {
	background-color: $color-blue-darker; @extend %styleguide-color;
}
.c--grey {
	background-color: $color-grey; @extend %styleguide-color;
}
.c--grey-light {
	background-color: $color-grey-light; @extend %styleguide-color;
}
.c--grey-dark {
	background-color: $color-grey-dark; @extend %styleguide-color;
}
.c--yellow {
	background-color: $color-yellow; @extend %styleguide-color;
}
.c--yellow-light {
	background-color: $color-yellow-light; @extend %styleguide-color;
}
.c--red {
	background-color: $color-red; @extend %styleguide-color;
}
.c--red-light {
	background-color: $color-red-light; @extend %styleguide-color;
}
.c--color-bg {
	background-color: $color-bg; @extend %styleguide-color;
}
.c--color-border {
	background-color: $color-border; @extend %styleguide-color;
}
.c--color-link {
	background-color: $color-link; @extend %styleguide-color;
}

