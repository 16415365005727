
.article__manchet {
	font-weight: 200;
	font-size: em(20);
}

.article-content {
	.article__title,
	.article__subheading {
		margin-bottom: em($space-small);
		font-size: em(16);
		font-weight: normal;
		text-transform: none;
	}
	.article__title {
		font-size: em(25);
		font-family: $klavika;
		margin-top: 25px;
		&:first-child {
	    	margin-top: 0;
	    }
	}

	.article__text {
		margin-bottom: em($space-large);
		font-size: em(16);
		//font-weight: 200;
	}

	.article__list {
		margin-left: em($space-medium);

		.article__list__item {
			font-size: em(16);
			font-weight: 200;
		}
	}

	.article__manchet {
		@extend .article__manchet;
	}

	.article__quote {
		display: inline-block;
		padding: em($space-medium) em($space-xlarge);
		//background: $color-coolwhite-light;
		border-radius: em(5);
		color: $color-grey;
		font-style: italic;
		font-size: em(14);
	}
	p {
		margin: rem($space-xlarge) 0;
		line-height: 1.6;
	}
	strong {
		font-weight: bold;
	}
	// p:first-child {
	// 	margin-top: 0;
	// }
}


// Richtexts
//
// All rich-text blocks should have the class `.rich-text` on their container element.
//
// Markup: ../../templates/styleguide/components/rich-text.hbs
//
// Style guide 1.3

.components-container > .deck:nth-child(2):not(.deck--aside) .article-content p:first-child {
	margin-top: 0;
}

.rich-text {
	h1 {
		@extend %h1;
	}
	h2,
	.article__title {
		margin-bottom: em($space-xsmall, 26);
		font: bold em(26)/1.2 $roboto;
		//@extend %h2;
		margin-top: em(20);
		&:first-child {
	    	margin-top: 0;
	    }
	}
	h2 + p {
		margin-top: 0;
	}
	h3 {
		font: bold em(16)/1.2 $roboto;
		&.subtitle {
			margin-bottom: em($space-large, $article-font-size);
		}
	}

	h4 {
		@extend %h4;
	}
	h5 {
		@extend %h5;
	}


	p, div, .article__text {
		@extend %paragraph;
	}
	strong {
		//font-size: em($article-font-size);
		font-size: 104% !important; // TODO - delete this if the font is regenerated
		font-weight: bold;
	}

	> .highlight {
		@extend h3;
		text-align: center;
	}

	ul, ol {
		margin-left: em($space-medium, $article-font-size);
		//font-weight: 200;
		padding-left: em(15, $article-font-size);
		> li {
			font-size: em($article-font-size);
			margin-bottom: 0.625em;
		}
	}

	ol {
		list-style: decimal outside;
		&.no-numbers {
			list-style: none;
		}
	}
	ul {
		list-style: disc outside;
		&.no-bullets {
			list-style: none;
		}
	}
	time {
		font: .625em/1 $roboto;
		color: $color-black;
	}
	em {
		font-style: italic;
		&.light {
			color: $color-grey;
		}
	}
	blockquote {
		display: inline-block;
		padding: em($space-medium, $article-font-size) em($space-xlarge, $article-font-size);
		background: $color-grey-light;
		border-radius: em(5);
		color: $color-grey-dark;
		font-style: italic;
		font-size: em($article-font-size);
		margin-bottom: em($space-large, $article-font-size);
	}
	iframe, object, embed {
		display: block;
		max-width: 100%;
		margin-left: auto;
		margin-right: auto;
	}
}

.heading {
	&--inline {
		display: inline-block;
	}
}


// Simple texts
//
// Simple text blocks who can live alone
//
// Markup: <p class="text {{modifier_class}}">Lorem ipsum</p>
//
// .text--indent   - Indent text with gutter-large space
//
// Style guide: 1.4

.text {
	@extend %paragraph;
	&.text--indent {
		margin-left: em($space-xlarge, 14);
	}
}


.article-columns--two-rows {
	column-gap: 24px;
	column-count: 1;
}

@include mq($from: screen-sm) {
	.article-columns--two-rows {
		column-count: 2;
	}
}

.text {
	&--small {
		font-size: em(14);
	}
	&--normal {
		font-size: em(16);
	}
	&--large {
		font-size: em(18);
	}
}
.t {
	&--blue {
		color: $color-blue;
	}
}
