$mq-static-breakpoint: screen-xxl;
$mq-responsive: false;

// Helpers

//@import "vendor/sass-list-maps";
//@import "../../bower_components/su/stylesheets/su";


@import "../../bower_components/sass-mq/mq";
@import "basis/settings";
@import "helpers/functions";
@import "helpers/mixin";
//@import "helpers/mq";
@import "helpers/colors";
//@import "helpers/colors";
@import "helpers/extends";
@import "helpers/helper-classes";


//Base
@import "basis/reset";
@import "basis/global";
@import "basis/animations";
@import "basis/rich-text";
@import "basis/images";
@import "basis/icons";
@import "basis/list";
@import "basis/navicons";
@import "basis/print";
@import "basis/icon-links";


//Critical layout
@import "vendor/simple-grid";
@import "vendor/semantic-grid";
@import "layout/grid";
@import "layout/row";


//Components
@import "components/overlay";
@import "components/buttons";
@import "components/burger-menu";
//@import "components/mobile-menu";
@import "components/deck";
@import "components/loader";

//@import "components/banner";
@import "components/modalbox";
@import "components/accordion";
//@import "components/table";
@import "components/cookie-alert";
@import "components/forms";
@import "components/link-list";


//Layout
@import "layout/header";
@import "layout/footer";
@import "pages/pages";


//Custom styling

//forms
.form__field--radio .form__item { margin-top: 7px; }

.form__field--select .form__container {

	.form__item {
		background: none !important;
		padding-right: inherit;
	}
}
.form__field--select {
	.form__container {
		&:before {
			display: none;
		}
	}
	select {
		padding-left:0;
	}
}


