// Header
//
// DOD: done
//
// The page header element. Including a search component.
//
// Should be places inside .page-wrapper element.
//
// Markup: ../../templates/prototype/partials/main-header.hbs
//
// Style guide: 3.3

.header {
	background-color: $color-white;
	height: em(80);
	position: relative;
	border-bottom: rem(1) solid $color-border; // TODO

	& > .w--normal {
		position: relative;
		height: inherit;
	}
	
	&__logo {
		position: relative;
		top: em(25);
		display: block;
		float: left;
		width: rem(150);
		> a {
			text-decoration: none;
		}
	}
	&__service-nav {
		position: relative;
		float: right;
		display: none;
		text-align: right;
		top: em(5);
		li {
			display: inline-block;
			margin-left: em($space-small);
			text-decoration: none;
			> a {
				color: $color-grey-dark;
				font-size: em(12);
				font-weight: normal;
				text-decoration: none;
				&:hover {
					color: $color-green;
				}
			}
			&.is--active {
				> a {
					color: $color-green;
					font-weight: bold;
				}
			}
		}
		@include mq($from:screen-sm) {
			display: block;
		}
	}
}

// .main-navigation,
// .header__service-nav {
// 	//transition: opacity .4s;
// }
.header__search ~ .main-navigation,
.header__search ~ .header__service-nav,
.header__search .header__search__initiator {
	transition: opacity .6s;
}
.header__search.is--active ~ .main-navigation,
.header__search.is--active ~ .header__service-nav,
.header__search.is--active .header__search__initiator {
	opacity: 0;
	transition: opacity .6s;
}

.header__search {
	position: absolute;
	top: em(27);
	right: em(50);
	//height: inherit;
	min-width: 90%;
	text-align: right;
	z-index: 1;
	//min-width: 60%;
	@include mq($from:screen-sm) {
		right: 0;
		top: em(37);
		min-width: 60%;
	}
	&__initiator {
		//float: right;
		position: absolute;
		top: 0;
		right: 0;
		width: 22px;
		height: 22px;
		line-height: 22px;
		text-align: center;
		> .magnifier-button {
			font-size: 22px;
			@include mq($from:screen-sm) {
				font-size: 16px;
			}
		}
		.is--active & {
			display: none;
		}
	}
	&.is--active {
		min-width: calc(100% - 50px);
		z-index: 3;
		@include mq($from:screen-sm) {
			min-width: 60%;
		}
	}
	&__input-con {
		display: block;
		position: absolute;
		top: rem(-14);
		width: 100%;
		right: 0;
		//min-width: 50%;
    	background-color: #fff;
    	//max-width: 100%;
    	padding-top: rem(9);
    	width: 0;
    	opacity: 0;
		visibility: visible;
    	transition: opacity .5s, width .5s;
    	@include mq($from: screen-sm){
    		top: rem(-24);
    	}
		.is--active & {
			visibility: visible;
			display: block;
			width: 100%;
			opacity: 1;
    		transition: opacity .5s, width .5s;
		}
		> .magnifier-button {
			position: absolute;
			top: em(12);
			right: em(14);
			font-size: em(22);
			padding: 0;
			border: 0;
			background-color: $color-white;
			z-index: 3;
			opacity: 0.6;
			@include mq($from:screen-sm) {
				font-size: em(16);
				top: em(21);
			}
			&:active,
			&:focus {
				outline: 0;
				opacity: 1;
			}
		}
		input {
			position: relative;
			right: 0;
			@extend %form-item;
			font-size: 16px;
			padding: 0;
			border: 0;
			border-radius: 0;
			border-bottom: rem(2) solid $color-green;
			.is--active & {
				padding: em(10) em(40) em(10) em(10);
			}
			&:focus {
				border-color: $color-green;
			}
		}
	}
}
.header__search__results {
	display: none;
	position: absolute;
	top: em(26);
	padding: 0;
	//border: rem(1) solid green;
	box-shadow: rem(1) rem(3) rem(5) rgba(0,0,0,.5);
	background-color: $color-white;
	width: 95%;
	text-align: left;
	.spinner {
		margin: em(4) auto em(8);
		display: none;
	}
	&.loading {
		.spinner {
			display: block;
		}
		.header__search__results__inner {
			display: none;
		}
	}
	&.is--active {
		display: block;
	}
	&__inner {
		display: block;
		.no-results {
			padding: rem(10) rem(20);
			font-size: 14px;
		}
	}
	&__result {
		display: block;
		padding: em(14) em(15) em(14) em(25);
		font-weight: normal;
		color: $color-black;
		font-size: em(15);
		text-decoration: none;
		&:hover,
		&.js--hover {
			background-color: darken($color-grey-light, 1);
		}
		& > span {
			font-weight: bold;
			text-decoration: underline;
			color: $color-black;
			font-size: em(15);
		}
	}
}