.page {
	&--basket {
		.notification, .basket {
			margin-bottom: em($gutter-normal);

		}
		.show-hide {
			margin-bottom: em($gutter-normal);
		}

		.basket__footer {
			@include clearfix();
			&__priceview[class*="grid"] {
				float: none;
				margin-bottom: em($space-medium);
				@include mq($from: screen-sm){
					float: right;
				}
			}
			&__voucher[class*="grid"] {
				float: none;
				@include mq($until: screen-md){
					width: 50%;
				}
				@include mq($from: screen-sm){
					float: left;
				}
				.box {
					margin-bottom: em($space-medium);
				}
			}
			&__button {
				width: 100%;
				@include mq($from: screen-sm){
					width: auto;
					float: right;
				}
			}
		}
	}
}
.checkout__backlink {
	display: inline-block;
	margin-bottom: em($space-small, 14);
	font-size: em(16);
}
.checkout-step1__notation {
	margin-top: em($space-medium);
	& > div {
		@include mq($from: screen-md){
			float: right;
		}
	}
}
.checkout-submit {
	height: 3.75em;
    line-height: 3.75em;
    min-width: 100%;
    @include mq($from: screen-md){
    	min-width: 250px;
    }
}
.form.checkout {
	input:not([type=checkbox]):not[type=radio] {
		font-size: em(16, 16);
		padding: em(9, 16);
	}
	input[type=checkbox],
	input[type=radio] {
		font-size: em(16, 16);
	}
}
.alt-delivery-check {
	.form__field--checkbox {
		margin-bottom: 0;
	}
}
.form--zipcode {
	position: relative;
	.spinner {
		display: none;
		position: absolute;
		top: em(45);
		right: em(20);
		z-index: 2;
		font-size: em(16);
		// width: em(16);
		// height: em(16);
		&.is--active {
			display: block;
		}
	}
}

// .page-wrapper.checkout {
// 	// .form__label {
// 	// 	font-size: em(16);
// 	// }
// 	.product-line--simple .product-line__title {
// 		font-size: em(16);
// 	}
// }