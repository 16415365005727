// Cookie alert
//
// DOD: done
//
// Cookie alerts are automatically written in the DOM tree. The text in the alert is determined in the in-page JS.
//
// Markup: ../../templates/prototype/partials/components/cookie-alert.hbs
//
// Style guide: 4.5
.cookie-alert {

	position: fixed;
	bottom:0;
	left:0;
	right:0;
	z-index: 6000;


	&__inner {
		background:rgba($color-blue-darker,0.85);
		color:$color-white;

		h4 {
			margin-top:0;
			color:$color-white;
		}
		.col-sm--3 {

			@include mq($from: screen-sm) {
				text-align: right;
			}
		}
		.button {
			@include mq($from: screen-sm) {
				width:80%;
			}
		}
		>.w--normal {
			padding: em(25) 0;
		}
	}
}