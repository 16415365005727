// Accordions
//
// DOD: done
//
// Accordions is used to show and hide content.
//
// Markup: ../../templates/prototype/partials/components/accordion.hbs
//
// Style guide: 4.11

.accordion {
	position: relative;
	list-style: none;
	padding-left: 0;

	margin: em($space-xxlarge) 0;

	h2 {
		@extend %h2;
		margin: em($space-xsmall) 0;
	}

	.accordion__title {
		display: block;
		//line-height: 62px;
		min-height: rem(32);
		padding: rem(16) 0 rem(14) rem(42);
		color: $color-black;
		font-size: em(18);
		border-bottom: rem(1) solid $color-grey;
		text-decoration: none;
		&:active,
		&:focus {
			color: $color-black;
			opacity: 1;
		}
		&:focus {
			border-bottom: rem(1) dashed $color-grey;
		}
		&:hover {
			color: $color-blue;
		}
	}
	
	&__item {
		border: solid $color-border;
		border-width: 0;
		position: relative;
		&.is--active > .accordion__title {
			border-bottom: 0 solid $color-grey;
		}
		&.is--active {
			border-width: 0 0 rem(1);
		}
		&.is--active:before {
			background-image: url(/static/media/accordion_minus.svg);
		}
		&:first-of-type {
			border-top-width: rem(1);
		}
		&:before {
			content: '';
			float: left;
			position: relative;
			margin: rem(8) 0 0 0;
			font-size: em(42);
			display: block;
			width: 1em;
			height: 1em;
			background-image: url(/static/media/plus.svg);
			background-position: center center;
			background-repeat: no-repeat;
		}
	}
	&__content {
		//transition: max-height .2s;
		display: none;
		overflow: hidden;
		margin: 0 0 rem(20) rem(42);
		// .is--active & {
		// 	display: block;
		// 	max-height: 0;
		// }
	}

	&__servicelink {
		margin: 0 0 em($space-small);
		display: block;
		text-align: center;
		@include mq($from: screen-xs){
			margin-top: 0;
			position: absolute;
			top: em(7);
			right: 0;
		}
		// margin-top: em(24); // TODO - depends on deck title font size
		// float: right;
		// min-width: em(20);
		& > a {
			font-size: em(15);
			text-decoration: underline;
			&:hover {
				text-decoration: none;
			}
		}
	}
}

// Accordion
//
// Accordions are made to hide and show content. Add ```.js--accordion``` to auto instantiate on pageload.
// >@Param:
//
// >el: [Html element]: markup should have the correct classes
//
// >@Param end
//
// Markup: ../../templates/prototype/partials/components/accordion.hbs
//
// Style guide: 5.1
