// --- google translate widget fix - start
// The google translate widget aggresively sets styles on html and body, which breaks page layout
// html {
//   height: auto !important;
// }
// body {
//   position: initial !important;
//   min-height: initial !important;
//   top: auto !important;
// }
// --- google translate widget fix - end

html {
	font-size: 100%;
	//background:$color-blue-darker;
}
body {
	font: normal 1em/1.4375 $roboto;
	margin: 0;
	color: $color-black;
	background-color: $color-white;
	//overflow-y: scroll;
	-webkit-font-smoothing: antialiased;
}
h1 {
    font-family: $roboto;
}
h2 {
    font-family: $roboto;
}
h3 {
	font-family: $roboto;
    color: $color-black;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 8px;
    //-webkit-font-smoothing: antialiased;
}
a {
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0.3);
	color: $color-link;
	text-decoration: underline;
	position: relative;
	transition: color .2s;
	> strong {
		font-weight: bold;
	}
	&:hover {
		color: $color-link-hover;
		outline: none;
		text-decoration: none;
	}
	&:focus {
		color: $color-link-hover;
		outline: none;
		opacity:0.6;
	}
	&:active {
		color: $color-link;
		opacity:0.6;
	}
	// &:visited {
	// 	color: $color-link;
	// 	&:hover,
	// 	&:active {
	// 		color: $color-link-hover;
	// 	}
	// }
	&.highlight {
		font: bold em(14)/3.21 $roboto;
	}
}
a[href],
input[type='submit'],
input[type='image'],
label[for],
select,
button,
.pointer {
	cursor: pointer;
}

.t--notice {
	color: $color-red;
	font-weight: bold;
	font-size: 110%;
}
.t--highlight {
	color: $color-green;
	font-weight: bold;
	font-size: 120%;
}

hr {
	border-width: 0;
	border-top: rem(1) solid $color-grey-light;
	margin-bottom: em($space-medium);
}

.logo {
	>.image-holder {
		max-width: em(157);
	}
}
.link {
	&--back {
		font-size: em(13);
		font-weight: bold;
		margin-top: em($space-small);
	}
}
input {
	&:focus {
		outline: none;
	}
}
template { // needs to be hidden in IE
	display: none;
}
#__bs_notify__ { // TODO
	display: none !important;
}
xmp {
	background-color: #fff;
	padding: 5px;
	white-space: pre-wrap;
}
code {
	background-color: $color-grey-light;
	padding: 12px;
	font-size: em(14);
	color: $color-black;
	font-style: italic;
	display: block
}
