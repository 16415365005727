.c--white, .c--black, .c--green, .c--blue, .c--blue-dark, .c--blue-darker, .c--grey, .c--grey-light, .c--grey-dark, .c--yellow, .c--yellow-light, .c--red, .c--red-light, .c--color-bg, .c--color-border, .c--color-link {
  display: inline-block;
  width: 200px;
  height: 50px;
  border-radius: 5px; }

.c--white {
  background-color: #fff; }

.c--black {
  background-color: #333333; }

.c--green {
  background-color: #005448; }

.c--blue {
  background-color: #4981A3; }

.c--blue-dark {
  background-color: #335057; }

.c--blue-darker {
  background-color: #1c2a2e; }

.c--grey {
  background-color: #e3e3e3; }

.c--grey-light {
  background-color: #f8f8f8; }

.c--grey-dark {
  background-color: #767676; }

.c--yellow {
  background-color: #ffda00; }

.c--yellow-light {
  background-color: #fceda6; }

.c--red {
  background-color: #f32f00; }

.c--red-light {
  background-color: #fccfc4; }

.c--color-bg {
  background-color: #f8f8f8; }

.c--color-border {
  background-color: #e3e3e3; }

.c--color-link {
  background-color: #4981A3; }

.rich-text h1 {
  font: 2em/1.4 "roboto", helvetica, arial, sans-serif;
  font-size: 2.625em; }

.deck__title, h1.deck__title {
  font: normal 2em/1.4 "klavika", helvetica, arial, sans-serif;
  font-size: 3em; }

.accordion h2 {
  font: 1.5625em/1.4 "roboto", helvetica, arial, sans-serif; }

.deck__title > a, h2.deck__title {
  font: normal 1.5625em/1.4 "klavika", helvetica, arial, sans-serif; }

.modal__header > h3, .rich-text.modal__header > .highlight {
  font: 1.3125em/1.4 "roboto", helvetica, arial, sans-serif; }

.rich-text h4 {
  font: bold 1em/1.4 "roboto", helvetica, arial, sans-serif;
  color: #333333; }
  .rich-text h4:not(&.collapse--bottom) {
    margin: 0 0 0.625em; }

.rich-text h5 {
  font: bold 0.9375em/1.4 "roboto", helvetica, arial, sans-serif; }
  .rich-text h5:not(&.collapse--bottom) {
    margin: 0 0 0.66667em; }

.rich-text p, .rich-text div, .rich-text .article__text, .text {
  font-family: "roboto", helvetica, arial, sans-serif;
  font-size: 0.9375em;
  line-height: 1.6;
  font-weight: normal; }
  .rich-text p:not(:last-child), .rich-text div:not(:last-child), .rich-text .article__text:not(:last-child), .text:not(:last-child) {
    margin-bottom: 1em; }
  .rich-text p.collapse, .rich-text div.collapse, .rich-text .collapse.article__text, .collapse.text {
    margin: 0; }
  .rich-text p > strong, .rich-text div > strong, .rich-text .article__text > strong, .text > strong {
    font-size: 1em; }
  .rich-text p > em, .rich-text div > em, .rich-text .article__text > em, .text > em {
    font-style: italic; }

.clearfix:before, .clearfix:after {
  content: "";
  display: table; }

.clearfix:after {
  clear: both; }

.w--normal {
  max-width: 62.5em;
  margin: 0 0.625em;
  margin: 0 auto; }

.w--narrow {
  max-width: 48em;
  margin: 0 0.625em;
  margin: 0 auto; }

.w--wide {
  max-width: 87.5em;
  margin: 0 0.625em;
  margin: 0 auto; }

.w--form {
  width: 36.5625em; }

.c--narrow {
  max-width: 37.5em; }

.c--indent {
  margin-left: 0;
  margin-left: 3.75em; }

.v--mobile-only {
  display: none !important; }

.v--sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0; }

.is--hidden {
  display: none; }

.is-calculation-height {
  visibility: hidden;
  position: absolute;
  width: 0; }

.gutter--small {
  margin-bottom: 0.9375em; }

.gutter--medium {
  margin-bottom: 1.25em; }

.gutter--large {
  margin-bottom: 1.5625em; }

.tc--light {
  color: #fff; }

.a--center {
  text-align: center !important; }

.a--left {
  text-align: left !important; }

.a--right {
  text-align: right !important; }

.va--middle {
  position: absolute;
  margin-top: auto;
  margin-bottom: auto;
  display: table;
  top: 0;
  bottom: 0; }

.f--right {
  float: right; }

.f--left {
  float: left; }

.b--top {
  border-top: 0.0625rem solid #e3e3e3; }

.b--bottom {
  border-bottom: 0.0625rem solid #e3e3e3; }

.rich-text p.t--small, .rich-text div.t--small, .rich-text .t--small.article__text, .t--small.text, .t--small {
  font-size: 0.75em; }

.t--strike {
  text-decoration: line-through; }

.t--bold {
  font-weight: bold; }

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, .rich-text > .highlight, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

html {
  font-size: 100%; }

body {
  font: normal 1em/1.4375 "roboto", helvetica, arial, sans-serif;
  margin: 0;
  color: #333333;
  background-color: #fff;
  -webkit-font-smoothing: antialiased; }

h1 {
  font-family: "roboto", helvetica, arial, sans-serif; }

h2 {
  font-family: "roboto", helvetica, arial, sans-serif; }

h3, .rich-text > .highlight {
  font-family: "roboto", helvetica, arial, sans-serif;
  color: #333333;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 8px; }

a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0.3);
  color: #4981A3;
  text-decoration: underline;
  position: relative;
  transition: color .2s; }
  a > strong {
    font-weight: bold; }
  a:hover {
    color: #335057;
    outline: none;
    text-decoration: none; }
  a:focus {
    color: #335057;
    outline: none;
    opacity: 0.6; }
  a:active {
    color: #4981A3;
    opacity: 0.6; }
  a.highlight {
    font: bold 0.875em/3.21 "roboto", helvetica, arial, sans-serif; }

a[href],
input[type='submit'],
input[type='image'],
label[for],
select,
button,
.pointer {
  cursor: pointer; }

.t--notice {
  color: #f32f00;
  font-weight: bold;
  font-size: 110%; }

.t--highlight {
  color: #005448;
  font-weight: bold;
  font-size: 120%; }

hr {
  border-width: 0;
  border-top: 0.0625rem solid #f8f8f8;
  margin-bottom: 1.25em; }

.logo > .image-holder {
  max-width: 9.8125em; }

.link--back {
  font-size: 0.8125em;
  font-weight: bold;
  margin-top: 0.9375em; }

input:focus {
  outline: none; }

template {
  display: none; }

#__bs_notify__ {
  display: none !important; }

xmp {
  background-color: #fff;
  padding: 5px;
  white-space: pre-wrap; }

code {
  background-color: #f8f8f8;
  padding: 12px;
  font-size: 0.875em;
  color: #333333;
  font-style: italic;
  display: block; }

.animation--fly-up {
  animation-fill-mode: forwards;
  animation-name: fly-up;
  animation-iteration-count: 1;
  animation-timing-function: "ease-in";
  animation-duration: 0.5s; }

@keyframes fly-up {
  0% {
    transform: translate3d(0, 100%, 0); }
  100% {
    transform: translate3d(0, 0%, 0); } }

.animation--fly-down {
  animation-fill-mode: forwards;
  animation-name: fly-down;
  animation-iteration-count: 1;
  animation-timing-function: "ease-in-out";
  animation-duration: 0.4s; }

@keyframes fly-down {
  0% {
    transform: translate3d(0, 0%, 0); }
  100% {
    transform: translate3d(0, 100%, 0); } }

.animation--shake {
  animation-name: shake;
  animation-duration: .5s;
  animation-fill-mode: both; }

@keyframes shake {
  0%, 100% {
    transform: translate3d(0, 0, 0); }
  15%, 45%, 70%, 90% {
    transform: translate3d(-3px, 0, 0); }
  30%, 60%, 80%, 95% {
    transform: translate3d(3px, 0, 0); } }

.animation--fade-in {
  animation-name: fadeIn;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: .8s; }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.animation--collapse-fade-out {
  animation-name: collapseFadeOut;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
  animation-duration: .8s; }

.animation--collapse-fade-out {
  overflow: hidden; }

@keyframes collapseFadeOut {
  0% {
    opacity: 1; }
  40% {
    opacity: 0;
    max-height: 400px; }
  100% {
    opacity: 0;
    max-height: 0; } }

.animation--fly-left {
  animation-name: flyLeft;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
  animation-duration: .8s; }

@keyframes flyLeft {
  0% {
    transform: translateX(0); }
  100% {
    transform: translateX(100%); } }

.animation--attention {
  animation-name: attention;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
  animation-duration: .4s; }

@keyframes attention {
  0% {
    transform: translateX(0); }
  50% {
    transform: translateX(30%); }
  100% {
    transform: translateX(0); } }

.animation--heartbeat {
  animation-name: heartbeat;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-duration: 2s; }

@keyframes heartbeat {
  0% {
    transform: scale(1); }
  50% {
    transform: scale(0.5); }
  100% {
    transform: translateX(1); } }

.article__manchet, .article-content .article__manchet {
  font-weight: 200;
  font-size: 1.25em; }

.article-content .article__title,
.article-content .article__subheading {
  margin-bottom: 0.9375em;
  font-size: 1em;
  font-weight: normal;
  text-transform: none; }

.article-content .article__title {
  font-size: 1.5625em;
  font-family: "klavika", helvetica, arial, sans-serif;
  margin-top: 25px; }
  .article-content .article__title:first-child {
    margin-top: 0; }

.article-content .article__text {
  margin-bottom: 1.5625em;
  font-size: 1em; }

.article-content .article__list {
  margin-left: 1.25em; }
  .article-content .article__list .article__list__item {
    font-size: 1em;
    font-weight: 200; }

.article-content .article__quote {
  display: inline-block;
  padding: 1.25em 1.875em;
  border-radius: 0.3125em;
  color: #e3e3e3;
  font-style: italic;
  font-size: 0.875em; }

.article-content p {
  margin: 1.875rem 0;
  line-height: 1.6; }

.article-content strong {
  font-weight: bold; }

.components-container > .deck:nth-child(2):not(.deck--aside) .article-content p:first-child {
  margin-top: 0; }

.rich-text h2,
.rich-text .article__title {
  margin-bottom: 0.38462em;
  font: bold 1.625em/1.2 "roboto", helvetica, arial, sans-serif;
  margin-top: 1.25em; }
  .rich-text h2:first-child,
  .rich-text .article__title:first-child {
    margin-top: 0; }

.rich-text h2 + p {
  margin-top: 0; }

.rich-text h3, .rich-text > .highlight {
  font: bold 1em/1.2 "roboto", helvetica, arial, sans-serif; }
  .rich-text h3.subtitle, .rich-text > .subtitle.highlight {
    margin-bottom: 1.66667em; }

.rich-text strong {
  font-size: 104% !important;
  font-weight: bold; }

.rich-text > .highlight {
  text-align: center; }

.rich-text ul, .rich-text ol {
  margin-left: 1.33333em;
  padding-left: 1em; }
  .rich-text ul > li, .rich-text ol > li {
    font-size: 0.9375em;
    margin-bottom: 0.625em; }

.rich-text ol {
  list-style: decimal outside; }
  .rich-text ol.no-numbers {
    list-style: none; }

.rich-text ul {
  list-style: disc outside; }
  .rich-text ul.no-bullets {
    list-style: none; }

.rich-text time {
  font: .625em/1 "roboto", helvetica, arial, sans-serif;
  color: #333333; }

.rich-text em {
  font-style: italic; }
  .rich-text em.light {
    color: #e3e3e3; }

.rich-text blockquote {
  display: inline-block;
  padding: 1.33333em 2em;
  background: #f8f8f8;
  border-radius: 0.3125em;
  color: #767676;
  font-style: italic;
  font-size: 0.9375em;
  margin-bottom: 1.66667em; }

.rich-text iframe, .rich-text object, .rich-text embed {
  display: block;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto; }

.heading--inline {
  display: inline-block; }

.text.text--indent {
  margin-left: 2.14286em; }

.article-columns--two-rows {
  column-gap: 24px;
  column-count: 1; }

.article-columns--two-rows {
  column-count: 2; }

.text--small {
  font-size: 0.875em; }

.text--normal {
  font-size: 1em; }

.text--large {
  font-size: 1.125em; }

.t--blue {
  color: #4981A3; }

.image-holder.image-holder--middle {
  font: 0/0 a; }
  .image-holder.image-holder--middle:before {
    content: ' ';
    display: inline-block;
    vertical-align: middle;
    height: 100%; }
  .image-holder.image-holder--middle > img,
  .image-holder.image-holder--middle .img {
    font: 16px/1.4 "roboto", helvetica, arial, sans-serif;
    display: inline-block; }

img {
  max-width: 100%;
  font-style: italic;
  vertical-align: middle; }

.gm-style img,
.map-canvas img[width],
.map-canvasimg[height] {
  max-width: none; }

.svg-icon {
  width: 1em;
  height: 1em;
  fill: #333333; }

.circle-icon {
  width: 1em;
  border: 0.0625em solid #333333;
  border-radius: 50%;
  position: relative;
  display: block;
  box-sizing: border-box; }
  .circle-icon.invert {
    border-color: #fff; }
    .circle-icon.invert > .svg-icon {
      fill: #fff; }
  .circle-icon.no-border {
    border-width: 0;
    border-radius: 0; }
  .circle-icon:before {
    content: '';
    display: block;
    position: relative;
    padding-top: 100%;
    width: 100%; }
  .circle-icon > .svg-icon {
    transition: fill .2s;
    width: .6em;
    height: .6em;
    position: absolute;
    top: 52%;
    left: 50%;
    transform: translate(-50%, -50%);
    fill: #333333; }
  .circle-icon .svg-icon--x {
    font-size: 65%; }

.list {
  list-style-position: inside; }
  .list.list--check > li,
  .list.list--check .list__item {
    padding-left: 1.875em;
    background: url(/static/media/checkmark-thin.svg) no-repeat 0 0.35714em transparent;
    font-size: 1em;
    line-height: 1.7;
    list-style-type: none; }
    .list.list--check > li--green,
    .list.list--check .list__item--green {
      padding-left: 1.375em;
      background: url(/static/media/checkmark-green.svg) no-repeat 0 0.35714em transparent; }
    .list.list--check > li.list__item--close,
    .list.list--check .list__item.list__item--close {
      background: url(/static/media/close.svg) no-repeat 0 0.35714em transparent; }
  .list.indent {
    margin-left: 1.5625em; }

li.list__item--check {
  padding-left: 1.875em;
  background: url(/static/media/checkmark-thin.svg) no-repeat 0 0.35714em transparent;
  font-size: 1em;
  line-height: 1.7;
  list-style-type: none !important; }
  li.list__item--check--green {
    padding-left: 1.375em;
    background: url(/static/media/checkmark-green.svg) no-repeat 0 0.35714em transparent; }

.navicon {
  padding: .5em 0;
  width: 1em;
  display: inline-block; }

.navicon--chevron .navicon__icon:before, .navicon--chevron .navicon__icon:after, .navicon--close .navicon__icon:before, .navicon--close .navicon__icon:after {
  transform-origin: 2% 50%; }

.navicon--chevron .navicon__icon {
  display: block;
  height: 0.0625rem;
  background: transparent;
  position: relative;
  width: 100%; }
  .navicon--chevron .navicon__icon:before {
    display: inline-block;
    width: 100%;
    height: 0.0625em;
    background: #333333;
    border-radius: 1em/14;
    transition: 0.2s;
    position: absolute;
    left: 0;
    content: '';
    top: 0rem;
    transform: rotate3d(0, 0, 1, 45deg);
    width: 80%; }
  .navicon--chevron .navicon__icon:after {
    display: inline-block;
    width: 100%;
    height: 0.0625em;
    background: #333333;
    border-radius: 1em/14;
    transition: 0.2s;
    position: absolute;
    left: 0;
    content: '';
    width: 80%;
    top: 0rem;
    transform: rotate3d(0, 0, 1, -45deg); }

.navicon--chevron.is--active .navicon__icon::before, .is--active .navicon--chevron .navicon__icon::before {
  width: 100%;
  transform-origin: 50% 50%; }

.navicon--chevron.is--active .navicon__icon::after, .is--active .navicon--chevron .navicon__icon::after {
  transform-origin: 50% 50%;
  width: 100%; }

.navicon--close .navicon__icon {
  display: block;
  height: 0.0625rem;
  background: transparent;
  position: relative;
  width: 100%; }
  .navicon--close .navicon__icon:before {
    display: inline-block;
    width: 100%;
    height: 0.0625em;
    background: #333333;
    border-radius: 1em/14;
    transition: 0.2s;
    position: absolute;
    left: 0;
    content: '';
    top: 0rem;
    width: 100%;
    transform: rotate3d(0, 0, 1, -45deg);
    transform-origin: 50% 50%; }
  .navicon--close .navicon__icon:after {
    display: inline-block;
    width: 100%;
    height: 0.0625em;
    background: #333333;
    border-radius: 1em/14;
    transition: 0.2s;
    position: absolute;
    left: 0;
    content: '';
    top: 0rem;
    width: 100%;
    transform: rotate3d(0, 0, 1, 45deg);
    transform-origin: 50% 50%; }

@media print {
  .reciept__header {
    text-align: left; }
  .reciept__header > span {
    float: none;
    display: inline; }
  .print-button,
  header,
  footer,
  a,
  .banner,
  .v--mobile-only,
  .footer-navigation,
  .filter-advanced,
  .product-line-simple__thumb,
  .mobile-menu-block {
    display: none !important; }
  .pagebreak {
    display: block;
    page-break-before: always; }
  .reciept__section {
    margin-bottom: 2.5em; }
  .box {
    padding: 0; }
  .print-logo {
    display: block;
    margin-top: 2.5em; }
  .notification {
    padding: 0; }
  .product-line-simple__info {
    margin-left: 0;
    width: 100%; } }

.icon-link {
  position: relative;
  display: inline-block; }
  .icon-link.icon-link--chevron:before {
    content: '';
    display: inline-block;
    margin-right: 0.625rem;
    background-image: url(/assets/gfx/iconlink/chevron.svg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 1em 1em;
    width: 1.375em;
    height: 1.25em;
    vertical-align: middle;
    position: relative;
    top: -0.125rem; }
  .icon-link.icon-link--chevron.icon-link--center:before {
    position: relative;
    top: 0; }
  .icon-link.icon-link--plus:before {
    content: '';
    display: inline-block;
    margin-right: 0.625rem;
    background-image: url(/assets/gfx/iconlink/plus.svg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 1em 1em;
    width: 1.375em;
    height: 1.25em;
    vertical-align: middle;
    position: relative;
    top: -0.125rem; }
  .icon-link.icon-link--plus.icon-link--center:before {
    position: relative;
    top: 0; }
  .icon-link.icon-link--arrow-left:before {
    content: '';
    display: inline-block;
    margin-right: 0.625rem;
    background-image: url(/assets/gfx/iconlink/arrow-left.svg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 1em 1em;
    width: 1.375em;
    height: 1.25em;
    vertical-align: middle;
    position: relative;
    top: -0.125rem; }
  .icon-link.icon-link--arrow-left.icon-link--center:before {
    position: relative;
    top: 0; }
  .icon-link.icon-link--arrow-right:before {
    content: '';
    display: inline-block;
    margin-right: 0.625rem;
    background-image: url(/assets/gfx/iconlink/arrow-right.svg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 1em 1em;
    width: 1.375em;
    height: 1.25em;
    vertical-align: middle;
    position: relative;
    top: -0.125rem; }
  .icon-link.icon-link--arrow-right.icon-link--center:before {
    position: relative;
    top: 0; }
  .icon-link.icon-link--facebook_logo:before {
    content: '';
    display: inline-block;
    margin-right: 0.625rem;
    background-image: url(/assets/gfx/iconlink/facebook_logo.svg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 1em 1em;
    width: 1.375em;
    height: 1.25em;
    vertical-align: middle;
    position: relative;
    top: -0.125rem; }
  .icon-link.icon-link--facebook_logo.icon-link--center:before {
    position: relative;
    top: 0; }
  .icon-link.icon-link--twitter:before {
    content: '';
    display: inline-block;
    margin-right: 0.625rem;
    background-image: url(/assets/gfx/iconlink/twitter.svg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 1em 1em;
    width: 1.375em;
    height: 1.25em;
    vertical-align: middle;
    position: relative;
    top: -0.125rem; }
  .icon-link.icon-link--twitter.icon-link--center:before {
    position: relative;
    top: 0; }
  .icon-link.icon-link--linkedin:before {
    content: '';
    display: inline-block;
    margin-right: 0.625rem;
    background-image: url(/assets/gfx/iconlink/linkedin.svg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 1em 1em;
    width: 1.375em;
    height: 1.25em;
    vertical-align: middle;
    position: relative;
    top: -0.125rem; }
  .icon-link.icon-link--linkedin.icon-link--center:before {
    position: relative;
    top: 0; }
  .icon-link.icon-link--opsigelse:before {
    content: '';
    display: inline-block;
    margin-right: 0.625rem;
    background-image: url(/assets/gfx/iconlink/opsigelse.svg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 1em 1em;
    width: 1.375em;
    height: 1.25em;
    vertical-align: middle;
    position: relative;
    top: -0.125rem; }
  .icon-link.icon-link--opsigelse.icon-link--center:before {
    position: relative;
    top: 0; }
  .icon-link.icon-link--forbrug:before {
    content: '';
    display: inline-block;
    margin-right: 0.625rem;
    background-image: url(/assets/gfx/iconlink/forbrug.svg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 1em 1em;
    width: 1.375em;
    height: 1.25em;
    vertical-align: middle;
    position: relative;
    top: -0.125rem; }
  .icon-link.icon-link--forbrug.icon-link--center:before {
    position: relative;
    top: 0; }
  .icon-link.icon-link--boligsoegning:before {
    content: '';
    display: inline-block;
    margin-right: 0.625rem;
    background-image: url(/assets/gfx/iconlink/boligsoegning.svg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 1em 1em;
    width: 1.375em;
    height: 1.25em;
    vertical-align: middle;
    position: relative;
    top: -0.125rem; }
  .icon-link.icon-link--boligsoegning.icon-link--center:before {
    position: relative;
    top: 0; }
  .icon-link.icon-link--svar-tilbud:before {
    content: '';
    display: inline-block;
    margin-right: 0.625rem;
    background-image: url(/assets/gfx/iconlink/svar-tilbud.svg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 1em 1em;
    width: 1.375em;
    height: 1.25em;
    vertical-align: middle;
    position: relative;
    top: -0.125rem; }
  .icon-link.icon-link--svar-tilbud.icon-link--center:before {
    position: relative;
    top: 0; }
  .icon-link.icon-link--print:before {
    content: '';
    display: inline-block;
    margin-right: 0.625rem;
    background-image: url(/assets/gfx/iconlink/print.svg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 1em 1em;
    width: 1.375em;
    height: 1.25em;
    vertical-align: middle;
    position: relative;
    top: -0.125rem; }
  .icon-link.icon-link--print.icon-link--center:before {
    position: relative;
    top: 0; }
  .icon-link.icon-link--svg-inline > svg {
    margin-right: 7px; }

.row {
  display: block;
  margin-left: -12.5px;
  margin-right: -12.5px; }
  .row:before, .row:after {
    content: "";
    display: table; }
  .row:after {
    clear: both; }
  .row:before, .row:after {
    content: "";
    display: table; }
  .row:after {
    clear: both; }
  .row > * {
    display: inline;
    float: left;
    box-sizing: border-box;
    padding-left: 12.5px;
    padding-right: 12.5px; }
  .row .col--1 {
    width: 8.33333%; }
  .row .col--2 {
    width: 16.66667%; }
  .row .col--3 {
    width: 25%; }
  .row .col--4 {
    width: 33.33333%; }
  .row .col--5 {
    width: 41.66667%; }
  .row .col--6 {
    width: 50%; }
  .row .col--7 {
    width: 58.33333%; }
  .row .col--8 {
    width: 66.66667%; }
  .row .col--9 {
    width: 75%; }
  .row .col--10 {
    width: 83.33333%; }
  .row .col--11 {
    width: 91.66667%; }
  .row .col--12 {
    width: 100%; }
  .row .col-sm--1 {
    width: 8.33333%; }
  .row .col-sm--2 {
    width: 16.66667%; }
  .row .col-sm--3 {
    width: 25%; }
  .row .col-sm--4 {
    width: 33.33333%; }
  .row .col-sm--5 {
    width: 41.66667%; }
  .row .col-sm--6 {
    width: 50%; }
  .row .col-sm--7 {
    width: 58.33333%; }
  .row .col-sm--8 {
    width: 66.66667%; }
  .row .col-sm--9 {
    width: 75%; }
  .row .col-sm--10 {
    width: 83.33333%; }
  .row .col-sm--11 {
    width: 91.66667%; }
  .row .col-sm--12 {
    width: 100%; }
  .row .col-md--1 {
    width: 8.33333%; }
  .row .col-md--2 {
    width: 16.66667%; }
  .row .col-md--3 {
    width: 25%; }
  .row .col-md--4 {
    width: 33.33333%; }
  .row .col-md--5 {
    width: 41.66667%; }
  .row .col-md--6 {
    width: 50%; }
  .row .col-md--7 {
    width: 58.33333%; }
  .row .col-md--8 {
    width: 66.66667%; }
  .row .col-md--9 {
    width: 75%; }
  .row .col-md--10 {
    width: 83.33333%; }
  .row .col-md--11 {
    width: 91.66667%; }
  .row .col-md--12 {
    width: 100%; }
  .row .col-lg--1 {
    width: 8.33333%; }
  .row .col-lg--2 {
    width: 16.66667%; }
  .row .col-lg--3 {
    width: 25%; }
  .row .col-lg--4 {
    width: 33.33333%; }
  .row .col-lg--5 {
    width: 41.66667%; }
  .row .col-lg--6 {
    width: 50%; }
  .row .col-lg--7 {
    width: 58.33333%; }
  .row .col-lg--8 {
    width: 66.66667%; }
  .row .col-lg--9 {
    width: 75%; }
  .row .col-lg--10 {
    width: 83.33333%; }
  .row .col-lg--11 {
    width: 91.66667%; }
  .row .col-lg--12 {
    width: 100%; }
  .row .col-xl--1 {
    width: 8.33333%; }
  .row .col-xl--2 {
    width: 16.66667%; }
  .row .col-xl--3 {
    width: 25%; }
  .row .col-xl--4 {
    width: 33.33333%; }
  .row .col-xl--5 {
    width: 41.66667%; }
  .row .col-xl--6 {
    width: 50%; }
  .row .col-xl--7 {
    width: 58.33333%; }
  .row .col-xl--8 {
    width: 66.66667%; }
  .row .col-xl--9 {
    width: 75%; }
  .row .col-xl--10 {
    width: 83.33333%; }
  .row .col-xl--11 {
    width: 91.66667%; }
  .row .col-xl--12 {
    width: 100%; }
  .row .col-xxl--1 {
    width: 8.33333%; }
  .row .col-xxl--2 {
    width: 16.66667%; }
  .row .col-xxl--3 {
    width: 25%; }
  .row .col-xxl--4 {
    width: 33.33333%; }
  .row .col-xxl--5 {
    width: 41.66667%; }
  .row .col-xxl--6 {
    width: 50%; }
  .row .col-xxl--7 {
    width: 58.33333%; }
  .row .col-xxl--8 {
    width: 66.66667%; }
  .row .col-xxl--9 {
    width: 75%; }
  .row .col-xxl--10 {
    width: 83.33333%; }
  .row .col-xxl--11 {
    width: 91.66667%; }
  .row .col-xxl--12 {
    width: 100%; }
  .row .col-xxxl--1 {
    width: 8.33333%; }
  .row .col-xxxl--2 {
    width: 16.66667%; }
  .row .col-xxxl--3 {
    width: 25%; }
  .row .col-xxxl--4 {
    width: 33.33333%; }
  .row .col-xxxl--5 {
    width: 41.66667%; }
  .row .col-xxxl--6 {
    width: 50%; }
  .row .col-xxxl--7 {
    width: 58.33333%; }
  .row .col-xxxl--8 {
    width: 66.66667%; }
  .row .col-xxxl--9 {
    width: 75%; }
  .row .col-xxxl--10 {
    width: 83.33333%; }
  .row .col-xxxl--11 {
    width: 91.66667%; }
  .row .col-xxxl--12 {
    width: 100%; }
  .row .col--push--1 {
    margin-left: 9.55882%; }
  .row .col--push--2 {
    margin-left: 17.89216%; }
  .row .col--push--3 {
    margin-left: 26.22549%; }
  .row .col--push--4 {
    margin-left: 34.55882%; }
  .row .col--push--5 {
    margin-left: 42.89216%; }
  .row .col--push--6 {
    margin-left: 51.22549%; }
  .row .col--push--7 {
    margin-left: 59.55882%; }
  .row .col--push--8 {
    margin-left: 67.89216%; }
  .row .col--push--9 {
    margin-left: 76.22549%; }
  .row .col--push--10 {
    margin-left: 84.55882%; }
  .row .col--push--11 {
    margin-left: 92.89216%; }
  .row .col--push--12 {
    margin-left: 101.22549%; }
  .row .col-sm-offset--1 {
    margin-left: 8.33333%; }
  .row .col-sm-offset--2 {
    margin-left: 16.66667%; }
  .row .col-sm-offset--3 {
    margin-left: 25%; }
  .row .col-sm-offset--4 {
    margin-left: 33.33333%; }
  .row .col-sm-offset--5 {
    margin-left: 41.66667%; }
  .row .col-sm-offset--6 {
    margin-left: 50%; }
  .row .col-sm-offset--7 {
    margin-left: 58.33333%; }
  .row .col-sm-offset--8 {
    margin-left: 66.66667%; }
  .row .col-sm-offset--9 {
    margin-left: 75%; }
  .row .col-sm-offset--10 {
    margin-left: 83.33333%; }
  .row .col-sm-offset--11 {
    margin-left: 91.66667%; }
  .row .col-sm-offset--12 {
    margin-left: 100%; }
  .row .col-md-offset--1 {
    margin-left: 8.33333%; }
  .row .col-md-offset--2 {
    margin-left: 16.66667%; }
  .row .col-md-offset--3 {
    margin-left: 25%; }
  .row .col-md-offset--4 {
    margin-left: 33.33333%; }
  .row .col-md-offset--5 {
    margin-left: 41.66667%; }
  .row .col-md-offset--6 {
    margin-left: 50%; }
  .row .col-md-offset--7 {
    margin-left: 58.33333%; }
  .row .col-md-offset--8 {
    margin-left: 66.66667%; }
  .row .col-md-offset--9 {
    margin-left: 75%; }
  .row .col-md-offset--10 {
    margin-left: 83.33333%; }
  .row .col-md-offset--11 {
    margin-left: 91.66667%; }
  .row .col-md-offset--12 {
    margin-left: 100%; }
  .row .col-lg-offset--1 {
    margin-left: 8.33333%; }
  .row .col-lg-offset--2 {
    margin-left: 16.66667%; }
  .row .col-lg-offset--3 {
    margin-left: 25%; }
  .row .col-lg-offset--4 {
    margin-left: 33.33333%; }
  .row .col-lg-offset--5 {
    margin-left: 41.66667%; }
  .row .col-lg-offset--6 {
    margin-left: 50%; }
  .row .col-lg-offset--7 {
    margin-left: 58.33333%; }
  .row .col-lg-offset--8 {
    margin-left: 66.66667%; }
  .row .col-lg-offset--9 {
    margin-left: 75%; }
  .row .col-lg-offset--10 {
    margin-left: 83.33333%; }
  .row .col-lg-offset--11 {
    margin-left: 91.66667%; }
  .row .col-lg-offset--12 {
    margin-left: 100%; }

.row--flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap; }
  .no-flexbox .row--flex {
    display: table;
    width: 100%; }
    .no-flexbox .row--flex:before, .no-flexbox .row--flex:after {
      content: "";
      display: table; }
    .no-flexbox .row--flex:after {
      clear: both; }
  .row--flex > .row__item {
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    margin-bottom: 0.9375rem;
    width: 100%;
    margin-bottom: 0; }
    .no-flexbox .row--flex > .row__item {
      display: table-cell;
      vertical-align: middle;
      width: auto; }
      .no-flexbox .row--flex > .row__item:last-child {
        text-align: right;
        float: right; }
    .row--flex > .row__item--no-shrink {
      flex: 0 0 auto; }
    .row--flex > .row__item.flexrow--last {
      order: 99; }
    .row--flex > .row__item:not(:first-child) {
      margin-left: 1.25em; }
    .row--flex > .row__item.flexrow--last {
      order: unset; }

.is--fixed .page-wrapper {
  max-height: 100%;
  width: 100%;
  height: 100%;
  position: fixed;
  -webkit-overflow-scrolling: auto;
  overflow-y: scroll; }

.is--blurred .page-wrapper {
  -webkit-filter: blur(3px);
  -moz-filter: blur(3px);
  filter: blur(3px); }

.overlay {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; }
  .overlay.overlay--modal {
    transition: opacity .2s, z-index .2s 0s;
    background: rgba(51, 51, 51, 0.4);
    z-index: -1;
    opacity: 0;
    text-align: center;
    overflow: hidden;
    overflow-y: auto;
    white-space: nowrap; }
    .overlay.overlay--modal.overlay--white {
      background: #fff; }
  .overlay.overlay--fixed {
    position: fixed; }
  .overlay.is--active {
    transition: opacity .2s;
    display: block;
    z-index: 8000;
    opacity: 1; }
  .overlay > .modal {
    display: inline-block;
    white-space: normal;
    vertical-align: middle;
    text-align: left; }
  .overlay:before {
    display: inline-block;
    overflow: hidden;
    width: 0;
    height: 100%;
    vertical-align: middle;
    content: ""; }

.button {
  transition: all .2s;
  background-color: transparent;
  padding: 0 1.5625rem;
  display: inline-block;
  overflow: hidden;
  vertical-align: top;
  box-sizing: border-box;
  border: 0.0625rem solid transparent;
  border-radius: 0.25rem;
  cursor: pointer;
  outline: none;
  user-select: none;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  font: normal 0.9375em/2.53333em "roboto", helvetica, arial, sans-serif;
  text-transform: lowercase;
  padding: 0 2em; }
  .button:first-letter {
    text-transform: uppercase; }
  .button--mini {
    padding: 0 1em; }
  .button--small {
    font-size: 0.875em;
    line-height: 2.85714em; }
  .button--mini {
    font-size: 0.6875em;
    line-height: 2.72727em;
    padding: 0 1em; }
  .button--wide {
    min-width: 10.9375rem; }
  .button:hover {
    text-decoration: none; }
  .button:not(.button--with-icon) > .svg-icon {
    display: none; }
  .button--bold {
    font-weight: 700; }

.button--primary {
  background-color: #005448;
  border: 0;
  color: #fff;
  font-weight: 700; }
  .button--primary:hover {
    background-color: #00453b;
    color: #fff; }
  .button--primary:visited {
    color: #fff; }
    .button--primary:visited:hover {
      color: #fff; }
  .button--primary > .svg-icon {
    fill: #fff; }
  .button--primary.w--border {
    border: 0.125rem solid #fff; }

.button--secondary {
  background-color: #fff;
  color: #005448;
  text-transform: lowercase;
  font-weight: 700; }
  .button--secondary:first-letter {
    text-transform: uppercase; }
  .button--secondary.button--with-icon {
    text-transform: none; }
  .button--secondary > .svg-icon {
    fill: #005448; }
  .button--secondary:hover, .button--secondary:active {
    background-color: #005448;
    color: #fff; }
    .button--secondary:hover > .svg-icon, .button--secondary:active > .svg-icon {
      fill: #fff; }
  .button--secondary:visited {
    color: #005448; }
    .button--secondary:visited:hover {
      color: #fff; }
  .button--secondary.w--border {
    border: 0.125rem solid #005448; }

.button--white {
  background-color: #fff;
  border: 0;
  color: #333333;
  font-weight: 700; }
  .button--white:hover {
    background-color: #f7f7f7;
    color: #005448; }
  .button--white:active {
    color: #005448; }
  .button--white:visited {
    color: #333333; }
    .button--white:visited:hover {
      color: #005448; }
  .button--white > .svg-icon {
    fill: #fff; }
  .button--white.w--border {
    border: 0.125rem solid #e3e3e3; }
  .button--white.t--bold {
    font-weight: 700; }

.button--inactive {
  background-color: #f8f8f8;
  font-weight: normal;
  color: #333333;
  cursor: default; }

.button--inverted {
  background-color: transparent;
  color: #fff;
  border-color: #fff;
  font-weight: bold; }
  .button--inverted:hover, .button--inverted:active {
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff; }
  .button--inverted:visited {
    color: #4981A3; }
    .button--inverted:visited:hover {
      color: #fff; }
  .button--inverted > .svg-icon {
    fill: #fff; }

.button--transparent {
  background-color: transparent;
  color: #fff;
  border-color: #fff;
  font-weight: 700; }
  .button--transparent:hover, .button--transparent:active {
    background-color: rgba(0, 0, 0, 0.2);
    color: #fff; }
  .button--transparent:visited {
    color: #fff; }
    .button--transparent:visited:hover {
      color: #fff; }
  .button--transparent > .svg-icon {
    fill: #fff; }
  .button--transparent.w--border {
    border: 0.125rem solid #fff; }

.button--transparent--dark {
  background-color: transparent;
  border: 0;
  color: #333333;
  font-weight: 700; }
  .button--transparent--dark:hover {
    background-color: rgba(0, 0, 0, 0.1);
    color: #005448; }
  .button--transparent--dark:focus, .button--transparent--dark:active {
    color: #005448; }
  .button--transparent--dark:visited {
    color: #005448; }
    .button--transparent--dark:visited:hover {
      color: #005448; }
  .button--transparent--dark > .svg-icon {
    fill: #fff; }
  .button--transparent--dark.w--border {
    border: 0.125rem solid #e3e3e3; }
    .button--transparent--dark.w--border:focus, .button--transparent--dark.w--border:active {
      border-color: #005448; }

.button.button--with-icon {
  padding: 0 1.875em 0 0.9375em; }
  .button.button--with-icon > .svg-icon {
    position: relative;
    top: 0.125rem;
    margin-right: 0.9375em; }
  .button.button--with-icon.button--add {
    min-width: 26.875em; }
    .button.button--with-icon.button--add > .svg-icon {
      margin-right: 0.3125em; }
  .button.button--with-icon.button--icon-right {
    padding: 0 0.9375em 0 1.875em; }
    .button.button--with-icon.button--icon-right > .svg-icon {
      top: 0.125rem;
      margin-left: 0.9375em;
      margin-right: 0; }

.btn-delete {
  background: none;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  padding: 0;
  cursor: pointer;
  -webkit-appearance: button;
  /* for input */
  -webkit-user-select: none;
  /* for button */
  -moz-user-select: none;
  -ms-user-select: none;
  font: normal 0.875em/1.2 "roboto", helvetica, arial, sans-serif;
  color: #4981A3; }
  .btn-delete:active, .btn-delete:focus {
    outline: 0; }
  .btn-delete > .circle-icon {
    display: inline-block;
    top: 0.1875em;
    font-size: 1em;
    transform: rotate(45deg);
    background-color: #f8f8f8;
    border-color: #f8f8f8; }
    .btn-delete > .circle-icon > .svg-icon {
      fill: #fff; }
  .btn-delete:hover, .btn-delete:active {
    color: #335057; }

.burger-menu .burger > .burger__cheese, .burger-menu .burger:before, .burger-menu .burger:after {
  content: '';
  display: block;
  height: 0.1875em;
  width: 100%;
  background-color: #333333;
  position: absolute;
  border-radius: 0.125rem; }

.burger-menu {
  width: 1.625em;
  cursor: pointer;
  position: absolute;
  top: 1.8125em;
  right: 0.3125em;
  display: none; }
  .burger-menu .burger {
    display: block;
    position: relative;
    padding: 0.4375em 100% 0.625em 0; }
    .burger-menu .burger > .burger__cheese {
      transition: opacity .2s, transform .2s; }
      .menu-active .burger-menu .burger > .burger__cheese {
        opacity: 0; }
    .burger-menu .burger:before {
      transition: transform .2s;
      top: 0; }
      .menu-active .burger-menu .burger:before {
        transform: rotate(45deg);
        top: 5px; }
    .burger-menu .burger:after {
      transition: transform .2s;
      bottom: 0; }
      .menu-active .burger-menu .burger:after {
        transform: rotate(-45deg);
        top: 5px; }
  .burger-menu .burger__caption {
    font-size: 0.6875em;
    text-align: center;
    color: #333333; }

@keyframes burger-up {
  0% {
    transform: rotate(3deg); }
  50% {
    transform: rotate(-3deg); }
  100% {
    transform: rotate(0deg); } }

.deck {
  position: relative;
  width: 100%; }
  .deck .w--narrow,
  .deck .w--normal,
  .deck .w--wide {
    position: relative;
    padding: 3.125rem 0; }
  .deck.half-padding .w--narrow,
  .deck.half-padding .w--normal,
  .deck.half-padding .w--wide {
    padding: 1.5625rem 0; }
  .deck.half-padding-bottom .w--narrow,
  .deck.half-padding-bottom .w--normal,
  .deck.half-padding-bottom .w--wide {
    padding-bottom: 1.5625rem; }
  .deck.half-padding-top .w--narrow,
  .deck.half-padding-top .w--normal,
  .deck.half-padding-top .w--wide {
    padding-top: 1.5625rem; }
  .deck.double-padding-top .w--narrow,
  .deck.double-padding-top .w--normal,
  .deck.double-padding-top .w--wide {
    position: relative;
    padding: 4.6875rem 0 3.125rem 0; }
  .deck.collapse--top .w--narrow,
  .deck.collapse--top .w--normal,
  .deck.collapse--top .w--wide {
    padding-top: 0; }
  .deck.collapse--bottom .w--narrow,
  .deck.collapse--bottom .w--normal,
  .deck.collapse--bottom .w--wide {
    padding-bottom: 0; }
  .deck.deck--with-tools .w--narrow,
  .deck.deck--with-tools .w--normal,
  .deck.deck--with-tools .w--wide {
    padding-top: 1.5625rem; }
  .deck.deck--with-tools .deck__tools {
    margin-bottom: 1.5625rem; }
  .deck.deck--aside {
    z-index: 2; }
    .deck.deck--aside .w--normal {
      padding: 0; }
  .deck__tools__title {
    font-size: 1.375em;
    font-weight: bold;
    color: #f8f8f8; }
  .deck__servicelink {
    margin-top: 1.25rem;
    display: block;
    text-align: center;
    margin-top: 0;
    position: absolute;
    top: 4.375rem;
    right: 0; }
    .deck__servicelink > a {
      font-size: 0.9375em;
      text-decoration: underline; }
      .deck__servicelink > a:hover {
        text-decoration: none; }
  .deck.w--page-header-box + .deck .w--normal {
    padding: 4.6875rem 0 3.125rem 0; }
  .deck.w--page-header-box + .deck.collapse-bottom .w--normal {
    padding: 4.6875rem 0 0 0; }
  .deck.deck--white + .deck--white .w--normal {
    padding-top: 0; }
  .deck.deck--color-bg + .deck--color-bg .w--normal {
    padding-top: 0; }

.deck__title {
  margin-bottom: 0.9375rem; }
  .deck__title--centered {
    text-align: center; }
    .deck__title--centered > a {
      text-align: center; }
  .deck__title--white {
    color: #fff; }
  .deck__title--sub {
    font-family: "roboto", helvetica, arial, sans-serif; }
  .deck__title > a {
    margin-bottom: 0.9375rem;
    color: #333333; }

.deck__title + p {
  margin-bottom: 1.25rem; }

.deck__title > .deck__subtitle {
  text-align: center;
  margin-bottom: 1.25rem; }

.deck--white {
  background: #fff; }

.deck--grey-light {
  background: #f8f8f8; }

.deck--color-bg {
  background: #f8f8f8; }

.deck--green {
  background: #005448; }

.deck--blue-dark {
  background: #335057; }

.deck--blue-darker {
  background: #1c2a2e; }

.deck--bgimg {
  background-repeat: no-repeat;
  background-position: top center;
  background-size: 120% auto;
  background-size: cover; }
  .deck--bgimg .w--narrow {
    margin: 0 auto; }
    .deck--bgimg .w--narrow .page-header {
      max-width: 70%;
      display: block;
      margin: 0 auto; }

.components-container > .deck:last-child > .w--normal {
  padding-bottom: 3.125rem; }

.deck--aside:last-child .image-box--inline {
  float: none;
  margin-left: 0;
  margin-right: 0; }

.components-container .deck--aside:nth-child(2) .w--normal {
  padding: 0 !important; }

.components-container .deck.w--page-header-box + .deck--aside aside {
  margin-top: 4.6875rem; }

.components-container .deck.w--page-header-box + .deck--aside + .deck {
  padding-top: 4.6875rem; }

.components-container .deck:first-child:not(.w--page-header-box) + .deck--aside:nth-child(2) + .deck .w--normal {
  padding-top: 3.125rem; }

.components-container .deck:first-child:not(.w--page-header-box) + .deck--aside:nth-child(2) aside {
  margin-top: 3.125rem; }

.components-container > .deck--aside:last-child {
  float: none; }
  .components-container > .deck--aside:last-child .image-box--inline, .components-container > .deck--aside:last-child .facts-box, .components-container > .deck--aside:last-child .info-box {
    float: none;
    margin-left: 0; }

.components-container > .deck--aside + .deck .rich-text p:first-child {
  margin-top: 0; }

.loader {
  position: absolute;
  text-align: center; }
  .loader > .spinner {
    position: absolute; }

.spinner {
  display: block;
  height: 1em;
  width: 1em;
  margin: 0 auto;
  animation: rotation 1s infinite linear;
  border-left: 0.125em solid rgba(0, 0, 0, 0.15);
  border-right: 0.125em solid rgba(0, 0, 0, 0.15);
  border-bottom: 0.125em solid rgba(0, 0, 0, 0.15);
  border-top: 0.125em solid rgba(0, 0, 0, 0.8);
  border-radius: 50%;
  font-size: 2em;
  box-sizing: border-box; }
  .spinner--white {
    border-left: 0.125em solid rgba(255, 255, 255, 0.15);
    border-right: 0.125em solid rgba(255, 255, 255, 0.15);
    border-bottom: 0.125em solid rgba(255, 255, 255, 0.15);
    border-top: 0.125em solid rgba(255, 255, 255, 0.8); }
  .no-cssanimations .spinner {
    border: 0;
    background: url(/static/media/spinner_32x32.gif) no-repeat center center transparent !important; }
    .no-cssanimations .spinner.spinner--small {
      background: url(/static/media/spinner_22x22.gif) no-repeat center center transparent !important; }
    .no-cssanimations .spinner.spinner--large {
      background: url(/static/media/spinner_44x44.gif) no-repeat center center transparent !important; }
  .spinner.spinner--small {
    font-size: 1.375em; }
  .spinner.spinner--large {
    font-size: 2.75em; }

@keyframes rotation {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(359deg); } }

.modal {
  transition: transform .2s;
  position: relative;
  width: 95%;
  background-color: #fff;
  margin-left: auto;
  margin-right: auto;
  padding: 3.75em 1.5625em 1.5625em;
  box-sizing: border-box;
  transform: scale(0);
  width: 32em;
  padding: 3.75em; }
  .is--active .modal {
    transition: transform .2s;
    transform: scale(1); }
  .modal__close {
    position: absolute;
    right: 0.9375em;
    top: 0.9375em;
    cursor: pointer; }
    .modal__close > .navicon {
      width: 1.125em; }
      .modal__close > .navicon > .navicon__icon {
        font-size: 2em; }
      .no--csstransforms .modal__close > .navicon {
        background-color: red; }
    .modal__close__text {
      display: block;
      font-size: 0.8125em;
      color: #e3e3e3;
      position: absolute;
      width: 1px;
      height: 1px;
      padding: 0;
      margin: -1px;
      overflow: hidden;
      clip: rect(0, 0, 0, 0);
      border: 0; }
  .modal.modal--large {
    width: 95%; }
  .modal.modal--large {
    width: 59.375em; }
  .modal.modal--gallery {
    width: auto;
    max-width: 85%;
    padding: 7.5em 0 0;
    vertical-align: top; }
    .modal.modal--gallery .modal__close {
      position: absolute;
      right: calc(50% - 25px);
      top: 4em;
      width: 2.25em;
      height: 2.25em;
      cursor: pointer;
      text-align: center;
      right: -2.375em;
      top: 5em; }
      .modal.modal--gallery .modal__close > .navicon {
        margin-top: 0.3125em;
        width: 2.25em; }
        .modal.modal--gallery .modal__close > .navicon > .navicon__icon {
          font-size: 2em; }
          .modal.modal--gallery .modal__close > .navicon > .navicon__icon:before, .modal.modal--gallery .modal__close > .navicon > .navicon__icon:after {
            background: #333333; }
        .no--csstransforms .modal.modal--gallery .modal__close > .navicon {
          background-color: red; }
  .modal.bg--transparent {
    background-color: transparent; }

.modal__header {
  border-bottom: 0.0625rem solid #e3e3e3;
  margin-bottom: 0.9375em; }
  .modal__header > .modal__subheader {
    font: normal 0.8125em/1.2 "roboto", helvetica, arial, sans-serif; }

.modal__content .notification {
  margin-bottom: 1.25em; }

.modal__content .product-line__blocklink {
  display: block; }

.modal__content .product-line .product-line__item--price {
  text-align: center; }
  .modal__content .product-line .product-line__item--price .button {
    width: 100%;
    margin-bottom: 0.3125em; }

.modal__footer {
  text-align: right; }
  .modal__footer > .button {
    min-width: inherit;
    width: inherit; }

.modal__content .basket {
  margin-bottom: 0; }
  .modal__content .basket .product__image > .image-holder {
    max-width: 3.125em; }

.accordion {
  position: relative;
  list-style: none;
  padding-left: 0;
  margin: 2.1875em 0; }
  .accordion h2 {
    margin: 0.625em 0; }
  .accordion .accordion__title {
    display: block;
    min-height: 2rem;
    padding: 1rem 0 0.875rem 2.625rem;
    color: #333333;
    font-size: 1.125em;
    border-bottom: 0.0625rem solid #e3e3e3;
    text-decoration: none; }
    .accordion .accordion__title:active, .accordion .accordion__title:focus {
      color: #333333;
      opacity: 1; }
    .accordion .accordion__title:focus {
      border-bottom: 0.0625rem dashed #e3e3e3; }
    .accordion .accordion__title:hover {
      color: #4981A3; }
  .accordion__item {
    border: solid #e3e3e3;
    border-width: 0;
    position: relative; }
    .accordion__item.is--active > .accordion__title {
      border-bottom: 0 solid #e3e3e3; }
    .accordion__item.is--active {
      border-width: 0 0 0.0625rem; }
    .accordion__item.is--active:before {
      background-image: url(/static/media/accordion_minus.svg); }
    .accordion__item:first-of-type {
      border-top-width: 0.0625rem; }
    .accordion__item:before {
      content: '';
      float: left;
      position: relative;
      margin: 0.5rem 0 0 0;
      font-size: 2.625em;
      display: block;
      width: 1em;
      height: 1em;
      background-image: url(/static/media/plus.svg);
      background-position: center center;
      background-repeat: no-repeat; }
  .accordion__content {
    display: none;
    overflow: hidden;
    margin: 0 0 1.25rem 2.625rem; }
  .accordion__servicelink {
    margin: 0 0 0.9375em;
    display: block;
    text-align: center;
    margin-top: 0;
    position: absolute;
    top: 0.4375em;
    right: 0; }
    .accordion__servicelink > a {
      font-size: 0.9375em;
      text-decoration: underline; }
      .accordion__servicelink > a:hover {
        text-decoration: none; }

.cookie-alert {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 6000; }
  .cookie-alert__inner {
    background: rgba(28, 42, 46, 0.85);
    color: #fff; }
    .cookie-alert__inner h4 {
      margin-top: 0;
      color: #fff; }
    .cookie-alert__inner .col-sm--3 {
      text-align: right; }
    .cookie-alert__inner .button {
      width: 80%; }
    .cookie-alert__inner > .w--normal {
      padding: 1.5625em 0; }

::-webkit-input-placeholder {
  transition: opacity .2s;
  line-height: 1em;
  color: #767676; }

:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset; }

:-moz-placeholder {
  transition: opacity .2s;
  line-height: 1em;
  color: #767676; }

::-moz-placeholder {
  transition: opacity .2s;
  line-height: 1em;
  color: #767676; }

:-ms-input-placeholder {
  transition: opacity .2s;
  line-height: 1em;
  color: #767676; }

[placeholder]:focus::-webkit-input-placeholder {
  opacity: .3; }

[placeholder]:focus:-moz-placeholder {
  opacity: .3; }

[placeholder]:focus::-moz-placeholder {
  opacity: .3; }

[placeholder]:focus:-ms-input-placeholder {
  opacity: .3; }

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0; }

.form__field--text > .form__item, .form__field--file > .form__item, .form__field--select .form__item, .form__field--textarea .form__item, .form__field--date .form__item, .header__search__input-con input {
  transition: border .2s;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  background-color: #fff;
  width: 100%;
  box-sizing: border-box;
  font: normal 0.875em/1.2 "roboto", helvetica, arial, sans-serif;
  margin: 0;
  padding: 0.85714em;
  border: 0.0625rem solid #e3e3e3;
  border-radius: 0.25em;
  min-height: 2.1875em;
  color: #333333;
  position: relative; }
  .form__field--text > .form__item:focus, .form__field--file > .form__item:focus, .form__field--select .form__item:focus, .form__field--textarea .form__item:focus, .form__field--date .form__item:focus, .header__search__input-con input:focus, .form__field--text > .form__item:active, .form__field--file > .form__item:active, .form__field--select .form__item:active, .form__field--textarea .form__item:active, .form__field--date .form__item:active, .header__search__input-con input:active {
    border-color: #005448;
    color: #333333; }

.form {
  position: relative;
  counter-reset: formgroup; }

.form__group .form__legend {
  font: normal 1.125em/1.2 "roboto", helvetica, arial, sans-serif;
  color: #333333;
  position: relative;
  margin-bottom: 0.9375em; }

.form__group.form__group--indent {
  margin-left: 1.875em; }

.form__field {
  position: relative; }
  .form__field + .form__field {
    margin-top: 0.9375em; }
  .form__field.collapse--bottom {
    margin-bottom: 0; }
  .form__field ~ .text--indent {
    margin-top: -0.9375em; }
  .form__field--text.form__field--inline:after, .form__field--text.form__field--inline:before {
    top: 0.8125em; }
  .form__field .form__label__text > strong {
    font-weight: bold; }
  .form__field--indented:last-child {
    margin-bottom: 0.625em; }
  .form__field--indented .form__label {
    transition: all .2s linear;
    color: #f8f8f8;
    font-weight: 200;
    font-size: 1em;
    margin-bottom: 0.9375em; }
  .form__field--indented .readMore {
    opacity: .3;
    transition: opacity .1s linear; }
  .form__field--indented__container {
    margin-left: 1.875em; }
    .form__field--indented__container > .form__field {
      margin-bottom: 0.3125em; }
  .form__field.is--checked + .form__field--indented__container .form__label {
    color: #333333; }
  .form__field--inline {
    display: inline-block;
    vertical-align: bottom; }
    .form__field--inline .form__item {
      display: block; }
    .form__field--inline .button {
      line-height: 2.5625em; }
    .form__field--inline.form__field--voucher {
      vertical-align: middle; }

.form__field--icon > .svg-icon {
  float: left; }

.form__field--icon > .form__label {
  display: block;
  margin-left: 4.375em; }

.form__label {
  position: relative;
  display: block;
  font-size: 1em;
  line-height: 1.4;
  color: #333333;
  line-height: 2.1; }
  .form__label > .text {
    text-decoration: underline; }
  .form--required .form__label > .form__label__text:after {
    content: ' *'; }
  .form__label > .tooltip {
    display: inline-block;
    margin-left: 0.4375em; }
  .form__label .readMore {
    position: relative;
    left: 0.625em; }

.form__label__img {
  max-height: 1.57143em; }

.form__field--with-icon {
  margin-bottom: 50px; }

.form__notice {
  display: none; }
  .is--error .form__notice {
    display: block;
    color: #f32f00;
    font-size: 0.875em;
    line-height: 1.7; }

.form__field--text > .form__item {
  transition: opacity .4s; }
  .form__field--text > .form__item::-ms-clear, .form__field--text > .form__item::-ms-reveal {
    display: none;
    width: 0;
    height: 0; }
  .form__field--text > .form__item[disabled] {
    opacity: .4; }
  .form__field--text > .form__item[readonly] {
    background-color: #f8f8f8; }

.form__field--text .form__label__text {
  font-weight: bold; }

.form__field--text.form__field--row > .form__item {
  display: inline-block;
  width: inherit; }

.form__field--text.form__field--row > .form__label {
  display: inline-block;
  padding-right: 2.14286em; }
  .form__field--text.form__field--row > .form__label > .tooltip {
    right: 0.35714em; }

.form__field--text.is--error > .form__item {
  border-color: #f32f00; }

.form__field--digits:after {
  content: attr(data-currency-type);
  position: absolute;
  display: block;
  top: 1.1875em;
  right: 2.5em; }

.form__field--digits .form__item {
  padding-right: 1.875em; }

.form__field--file > .form__item {
  transition: opacity .4s;
  padding: 0.85714em;
  padding: 0;
  border: 0.0625rem solid transparent;
  width: auto;
  min-width: 12.5em;
  padding-left: 60px;
  padding-top: 10px; }
  .form__field--file > .form__item::-ms-clear, .form__field--file > .form__item::-ms-reveal {
    display: none;
    width: 0;
    height: 0; }
  .form__field--file > .form__item[disabled] {
    opacity: .4; }
  .form__field--file > .form__item[readonly] {
    background-color: #f8f8f8; }
  .form__field--file > .form__item::-webkit-file-upload-button {
    visibility: hidden; }
  .form__field--file > .form__item:focus, .form__field--file > .form__item:active {
    border-color: transparent; }
  .form__field--file > .form__item:before {
    content: attr(data-title);
    display: inline-block;
    background-color: #005448;
    color: white;
    border: 0;
    border-radius: 0.1875rem;
    padding: 0.85714em 1.57143em;
    margin-left: -60px;
    margin-top: -10px;
    outline: none;
    white-space: nowrap;
    -webkit-user-select: none;
    cursor: pointer;
    transition: background-color .25s ease; }
  .form__field--file > .form__item:hover::before {
    background-color: #00453b; }
  .form__field--file > .form__item:active::before {
    background-color: #00453b; }

.form__field--file .form__label__text {
  font-weight: bold; }

.form__field--file.form__field--row > .form__item {
  display: inline-block;
  width: inherit; }

.form__field--file.form__field--row > .form__label {
  display: inline-block;
  padding-right: 2.14286em; }
  .form__field--file.form__field--row > .form__label > .tooltip {
    right: 0.35714em; }

.form__field--file.is--error > .form__item {
  border-color: #f32f00; }

.form__field--select .form__container {
  position: relative; }

.form__field--select.is--focus .form__container:after {
  border-left-color: #f8f8f8; }

.form__field--select .form__item {
  padding-right: 2.6875em;
  text-indent: .01px;
  font: normal 1em/1 "roboto", helvetica, arial, sans-serif;
  background-image: url(/static/media/dropdown_arrow.svg);
  background-position: center right 10px;
  background-repeat: no-repeat; }
  .form__field--select .form__item:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #333333; }
  .form__field--select .form__item::-ms-expand {
    display: none; }
  .form__field--select .form__item:focus {
    outline: 0 !important;
    border-color: #333333; }

.form__field--select .form__label {
  font-weight: bold; }

.form__field--select.is--error .form__item {
  border-color: #f32f00; }

.form__field--select.is--error .form__container:after {
  border-color: #f32f00; }

.form__field--textarea .form__item {
  transition: min-height .2s ease-in-out;
  display: block;
  width: 100%;
  min-height: 6.25em;
  resize: none; }
  .form__field--textarea .form__item:focus {
    min-height: 12.5em; }

.form__field--textarea .form__label {
  font-weight: bold; }

.form__field--textarea.is--error > .form__item {
  border-color: #f32f00; }

.form__field--date .form__item {
  background: url(/static/media/calendar.svg) no-repeat center center;
  background-repeat: no-repeat;
  background-position: calc(100% - 15px) center; }

.form__field--radio .form__item {
  vertical-align: top;
  margin-top: 0.5625em; }

.form__field--radio .form__label {
  position: relative; }
  .form__field--radio .form__label--sub {
    display: block;
    text-transform: lowercase; }

.form__field--radio .form__label__text {
  display: inline;
  margin-left: 0.1875em; }

.form__field--radio.invert .form__item {
  left: inherit;
  right: 0; }

.form__field--radio.invert .form__label {
  padding: 0 1.875em 0 0; }

.form__field--checkbox .form__item {
  vertical-align: top;
  margin-top: 0.625em; }

.form__field--checkbox .form__label {
  position: relative; }

.form__field--checkbox .form__label__text {
  display: inline-block;
  max-width: calc(100% - 30px);
  margin-left: 0.1875em; }

.form__field--checkbox.invert .form__item {
  left: inherit;
  right: 0; }

.form__field--checkbox.invert .form__label {
  padding: 0 1.875em 0 0; }

.form__label--media .form__label__img {
  position: absolute;
  margin-top: auto;
  margin-bottom: auto;
  display: table;
  top: 0;
  bottom: 0;
  float: left;
  display: block; }

.form__label--media .form__label__text {
  display: block;
  margin-left: 3.21429em;
  line-height: 1.2; }
  .form__label--media .form__label__text > .t--small {
    display: block; }

.form__sub-total {
  padding-top: 1.875em;
  border-bottom: 0.125rem solid #333333;
  font-weight: 500; }
  .form__sub-total > .form__sub-total__price {
    float: right;
    font-weight: 500; }

.link-list {
  margin: 2.1875em 0; }
  .link-list--collapse-bottom {
    margin-bottom: 0; }
  .link-list--collapse-top-bottom {
    margin-top: 0.9375em;
    margin-bottom: 0; }
  .link-list.inline {
    margin: 1.25em 0; }
  .link-list .icon-link {
    margin: 0 1.25em;
    padding: 0 0.9375em; }
  .link-list.with-bullets li:not(.flex-item) {
    padding-left: 1.5625em; }
  .link-list.with-bullets .flex-item > .link-list__item {
    display: inline-block;
    position: relative;
    padding-left: 0.9375em; }
    .link-list.with-bullets .flex-item > .link-list__item:before {
      background: url(/static/media/list-bullet.png) no-repeat center center;
      width: 15px;
      height: 15px;
      content: '';
      position: absolute;
      top: 5px;
      left: 0px; }
  .link-list.block-links .link-list__link {
    display: block;
    margin-right: 4%; }
  .link-list__item {
    margin-bottom: 0.9375em; }
    .page-header .link-list__item {
      margin-bottom: 0.3125em; }
  .link-list a {
    text-decoration: underline; }
    .link-list a:hover {
      text-decoration: none; }
  .link-list__content {
    display: block;
    margin: 0.3125em 4% 2.5em 0; }
  .link-list.link-list--icons .link-list__item {
    padding: 1.5625em 2%;
    width: 94%;
    text-align: center; }
    .link-list.link-list--icons .link-list__item > .link-list__link {
      margin: 0 auto;
      max-width: 90%;
      display: block;
      text-decoration: none;
      max-width: 60%; }
      .link-list.link-list--icons .link-list__item > .link-list__link svg {
        z-index: 2;
        margin: 0 auto;
        position: relative;
        font-size: 3.75em;
        display: block;
        margin-bottom: 0.25em;
        transition: fill 0.2s; }
      .link-list.link-list--icons .link-list__item > .link-list__link:hover svg {
        fill: #fff; }
      .link-list.link-list--icons .link-list__item > .link-list__link:hover:after {
        opacity: 1; }
      .link-list.link-list--icons .link-list__item > .link-list__link:after {
        content: '';
        display: block;
        background-color: #005448;
        border-radius: 6px;
        position: absolute;
        top: -0.625em;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;
        width: 5em;
        height: 5em;
        transition: all 0.2s;
        opacity: 0; }

.header {
  background-color: #fff;
  height: 5em;
  position: relative;
  border-bottom: 0.0625rem solid #e3e3e3; }
  .header > .w--normal {
    position: relative;
    height: inherit; }
  .header__logo {
    position: relative;
    top: 1.5625em;
    display: block;
    float: left;
    width: 9.375rem; }
    .header__logo > a {
      text-decoration: none; }
  .header__service-nav {
    position: relative;
    float: right;
    display: none;
    text-align: right;
    top: 0.3125em;
    display: block; }
    .header__service-nav li {
      display: inline-block;
      margin-left: 0.9375em;
      text-decoration: none; }
      .header__service-nav li > a {
        color: #767676;
        font-size: 0.75em;
        font-weight: normal;
        text-decoration: none; }
        .header__service-nav li > a:hover {
          color: #005448; }
      .header__service-nav li.is--active > a {
        color: #005448;
        font-weight: bold; }

.header__search ~ .main-navigation,
.header__search ~ .header__service-nav,
.header__search .header__search__initiator {
  transition: opacity .6s; }

.header__search.is--active ~ .main-navigation,
.header__search.is--active ~ .header__service-nav,
.header__search.is--active .header__search__initiator {
  opacity: 0;
  transition: opacity .6s; }

.header__search {
  position: absolute;
  top: 1.6875em;
  right: 3.125em;
  min-width: 90%;
  text-align: right;
  z-index: 1;
  right: 0;
  top: 2.3125em;
  min-width: 60%; }
  .header__search__initiator {
    position: absolute;
    top: 0;
    right: 0;
    width: 22px;
    height: 22px;
    line-height: 22px;
    text-align: center; }
    .header__search__initiator > .magnifier-button {
      font-size: 22px;
      font-size: 16px; }
    .is--active .header__search__initiator {
      display: none; }
  .header__search.is--active {
    min-width: calc(100% - 50px);
    z-index: 3;
    min-width: 60%; }
  .header__search__input-con {
    display: block;
    position: absolute;
    top: -0.875rem;
    width: 100%;
    right: 0;
    background-color: #fff;
    padding-top: 0.5625rem;
    width: 0;
    opacity: 0;
    visibility: visible;
    transition: opacity .5s, width .5s;
    top: -1.5rem; }
    .is--active .header__search__input-con {
      visibility: visible;
      display: block;
      width: 100%;
      opacity: 1;
      transition: opacity .5s, width .5s; }
    .header__search__input-con > .magnifier-button {
      position: absolute;
      top: 0.75em;
      right: 0.875em;
      font-size: 1.375em;
      padding: 0;
      border: 0;
      background-color: #fff;
      z-index: 3;
      opacity: 0.6;
      font-size: 1em;
      top: 1.3125em; }
      .header__search__input-con > .magnifier-button:active, .header__search__input-con > .magnifier-button:focus {
        outline: 0;
        opacity: 1; }
    .header__search__input-con input {
      position: relative;
      right: 0;
      font-size: 16px;
      padding: 0;
      border: 0;
      border-radius: 0;
      border-bottom: 0.125rem solid #005448; }
      .is--active .header__search__input-con input {
        padding: 0.625em 2.5em 0.625em 0.625em; }
      .header__search__input-con input:focus {
        border-color: #005448; }

.header__search__results {
  display: none;
  position: absolute;
  top: 1.625em;
  padding: 0;
  box-shadow: 0.0625rem 0.1875rem 0.3125rem rgba(0, 0, 0, 0.5);
  background-color: #fff;
  width: 95%;
  text-align: left; }
  .header__search__results .spinner {
    margin: 0.25em auto 0.5em;
    display: none; }
  .header__search__results.loading .spinner {
    display: block; }
  .header__search__results.loading .header__search__results__inner {
    display: none; }
  .header__search__results.is--active {
    display: block; }
  .header__search__results__inner {
    display: block; }
    .header__search__results__inner .no-results {
      padding: 0.625rem 1.25rem;
      font-size: 14px; }
  .header__search__results__result {
    display: block;
    padding: 0.875em 0.9375em 0.875em 1.5625em;
    font-weight: normal;
    color: #333333;
    font-size: 0.9375em;
    text-decoration: none; }
    .header__search__results__result:hover, .header__search__results__result.js--hover {
      background-color: whitesmoke; }
    .header__search__results__result > span {
      font-weight: bold;
      text-decoration: underline;
      color: #333333;
      font-size: 0.9375em; }

.footer {
  color: #fff; }
  .footer__logo {
    padding-bottom: 0.75em;
    text-align: center;
    padding-bottom: 0;
    text-align: left; }
  .footer__address {
    padding-top: 0.3125em;
    text-align: center;
    color: #767676;
    font-size: 0.75em; }
  .footer__language {
    padding-top: 0.75em;
    text-align: center;
    padding-top: 0;
    text-align: right; }
    .footer__language .goog-te-gadget {
      font-family: "roboto", helvetica, arial, sans-serif; }
      .footer__language .goog-te-gadget .goog-te-gadget-simple {
        padding: 2px 10px;
        border-radius: 3px;
        background-color: #203034;
        border-left: 1px solid rgba(227, 227, 227, 0.2);
        border-top: 1px solid rgba(227, 227, 227, 0.2);
        border-bottom: 1px solid rgba(227, 227, 227, 0.2);
        border-right: 1px solid rgba(227, 227, 227, 0.2);
        font-size: 14px;
        color: #fff;
        display: inline-block;
        padding-top: 2px;
        padding-bottom: 3px;
        cursor: pointer;
        zoom: 1; }
        .footer__language .goog-te-gadget .goog-te-gadget-simple img.goog-te-gadget-icon {
          margin-top: 4px;
          background-image: url(/static/media/google-g.svg) !important;
          background-repeat: no-repeat;
          background-position: 0 0 !important; }
        .footer__language .goog-te-gadget .goog-te-gadget-simple a.goog-te-menu-value {
          color: #fff;
          font-weight: 200; }
          .footer__language .goog-te-gadget .goog-te-gadget-simple a.goog-te-menu-value span:nth-last-of-type(1) {
            color: #fff !important;
            font-size: 8px; }
          .footer__language .goog-te-gadget .goog-te-gadget-simple a.goog-te-menu-value span:nth-last-of-type(2) {
            display: none; }
      .footer__language .goog-te-gadget:hover .goog-te-gadget-simple {
        border: 1px solid rgba(255, 255, 255, 0.6); }

.page--basket .notification, .page--basket .basket {
  margin-bottom: 1.5625em; }

.page--basket .show-hide {
  margin-bottom: 1.5625em; }

.page--basket .basket__footer:before, .page--basket .basket__footer:after {
  content: "";
  display: table; }

.page--basket .basket__footer:after {
  clear: both; }

.page--basket .basket__footer__priceview[class*="grid"] {
  float: none;
  margin-bottom: 1.25em;
  float: right; }

.page--basket .basket__footer__voucher[class*="grid"] {
  float: none;
  float: left; }
  .page--basket .basket__footer__voucher[class*="grid"] .box {
    margin-bottom: 1.25em; }

.page--basket .basket__footer__button {
  width: 100%;
  width: auto;
  float: right; }

.checkout__backlink {
  display: inline-block;
  margin-bottom: 1.07143em;
  font-size: 1em; }

.checkout-step1__notation {
  margin-top: 1.25em; }
  .checkout-step1__notation > div {
    float: right; }

.checkout-submit {
  height: 3.75em;
  line-height: 3.75em;
  min-width: 100%;
  min-width: 250px; }

.form.checkout input:not([type=checkbox]):not[type=radio] {
  font-size: 1em;
  padding: 0.5625em; }

.form.checkout input[type=checkbox],
.form.checkout input[type=radio] {
  font-size: 1em; }

.alt-delivery-check .form__field--checkbox {
  margin-bottom: 0; }

.form--zipcode {
  position: relative; }
  .form--zipcode .spinner {
    display: none;
    position: absolute;
    top: 2.8125em;
    right: 1.25em;
    z-index: 2;
    font-size: 1em; }
    .form--zipcode .spinner.is--active {
      display: block; }

.form__field--radio .form__item {
  margin-top: 7px; }

.form__field--select .form__container .form__item {
  background: none !important;
  padding-right: inherit; }

.form__field--select .form__container:before {
  display: none; }

.form__field--select select {
  padding-left: 0; }
