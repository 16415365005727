// Icon links
//
// Icon link is used as global link prefixed with an icon
//
// Markup: ../../templates/prototype/partials/components/icon-link.hbs
//
// .icon-link--chevron			- Chevron
// .icon-link--plus				- Plus
// .icon-link--arrow-left		- Arrow left (white)
// .icon-link--arrow-right		- Arrow right (white)
// .icon-link--facebook_logo	- Facebook (for footer - white)
// .icon-link--twitter			- Twitter icon (for footer - white)
// .icon-link--linkedin			- Linkedin icon (for footer - white)
// .icon-link--opsigelse		- Opsigelse (white)
// .icon-link--forbrug			- Forbrug (white)
// .icon-link--boligsoegning	- Boligsøgning (white)
// .icon-link--svar-tilbud		- Svar på tilbud (white)
// .icon-link--print			- ..
//
// Style guide: 1.8

$icon-size: 20 !default;
%icon-basic {
	content: '';
	display: inline-block;
	background-position: left center;
	background-repeat: no-repeat;
	background-size: em($icon-size) em($icon-size);
	margin-right: em($space-small);
	float: left;
}

@mixin icon-link($name){
	&.icon-link--#{$name} {
		&:before {
			content: '';
			display: inline-block;
			margin-right: rem(10);
			background-image: url(/assets/gfx/iconlink/#{$name}.svg);
			background-position: center center;
			background-repeat: no-repeat;
			background-size: 1em 1em;
			width: em($icon-size+2);
			height: em($icon-size);
			vertical-align: middle;
			position: relative;
			top: rem(-2);
			//left: 0;
		}
	}
	&.icon-link--#{$name}.icon-link--center {
		&:before {
			position: relative;
			top: 0;

		}
	}
}
.icon-link {
	position: relative;
	display: inline-block;
	//padding-left: rem($space-medium);
	//@include icon-link("phone");
	//@include icon-link("envelope");
	@include icon-link("chevron");
	@include icon-link("plus");
	@include icon-link("arrow-left");
	@include icon-link("arrow-right");
	@include icon-link("facebook_logo");
	@include icon-link("twitter");
	@include icon-link("linkedin");
	@include icon-link("opsigelse");
	@include icon-link("forbrug");
	@include icon-link("boligsoegning");
	@include icon-link("svar-tilbud");
	@include icon-link("print");
	&.icon-link--svg-inline {
		& > svg {
			margin-right: 7px;
		}
	}
}
