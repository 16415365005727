.page-wrapper {
	.is--fixed & {
		max-height: 100%;
		width: 100%;
		height: 100%;
		position: fixed;
		-webkit-overflow-scrolling: auto;
		//overflow: hidden;
		overflow-y: scroll;
	}
	.is--blurred & {
		-webkit-filter: blur(3px);
		-moz-filter: blur(3px);
		filter: blur(3px);
  	}
}

.overlay {
	position: absolute;
  	content: "";
  	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
 	&.overlay--modal {
  		transition: opacity .2s, z-index .2s 0s;
  		background: rgba($color-black, 0.4);
	 	z-index: -1;
	 	opacity: 0;
		text-align: center;
	 	overflow: hidden;
	 	overflow-y: auto;
	 	white-space: nowrap;
	 	&.overlay--white {
			background: #fff;
		}
	}

	&.overlay--fixed {
		position: fixed;
	}
	&.is--active {
		transition: opacity .2s;
		display: block;
		z-index: 8000; // ref. in _settings
		opacity: 1;
  	}
	>.modal {
		display: inline-block;
	    white-space: normal;
	    vertical-align: middle;
	    text-align: left;
	}

	&:before {
		display: inline-block;
	  overflow: hidden;
	  width: 0;
	  height: 100%;
	  vertical-align: middle;
	  content: "";
	}
}
