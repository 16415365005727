
// Link list
//
// DOD: done
//
// link: ../prototype/link-list-examples.html
//
// Link lists have a bunch of variations. Sizing of the items depends on .flex-row and .flex-item.
//
// **Note:** It's recommended that when items have text content, that the flex-row is given the .align-left class for a better result.
//
// Markup: ../../templates/prototype/partials/components/link-list.hbs
//
// Style guide: 4.13

.link-list {
	margin: em($gutter-large) 0;

	&--collapse-bottom {
		margin-bottom:0;
	}

	&--collapse-top-bottom {
		margin-top:em($space-small);
		margin-bottom:0;
	}

	&.inline {
		margin: em(20) 0;
	}
	.icon-link {
		margin: 0 em($space-medium);
		padding: 0 em($space-small);
	}
	&.with-bullets {
		li:not(.flex-item) {
			padding-left: em(25);
		}
		.flex-item > .link-list__item {
			display: inline-block;
			position: relative;
			// list-style-position: outside;
			// list-style-image: url(/static/media/list-bullet.png);
			&:before {
				background: url(/static/media/list-bullet.png) no-repeat center center;
				width: 15px;
				height: 15px;
				content: '';
				position: absolute;
				top: 5px;
				left: 0px;
			}
			padding-left: em(15);
		}
	}
	&.block-links {
		.link-list__link {
			display: block;
			margin-right: 4%;
		}
	}
	&__item {
		margin-bottom: em(15);
		.page-header & {
			margin-bottom: em(5);
		}
	}
	a {
		text-decoration: underline;
		&:hover {
			text-decoration: none;
		}
	}

	&__content {
		display: block;
		margin: em(5) 4% em(40) 0;
	}
	&.link-list--icons {
		& .link-list__item {
			padding: em($space-large) 2%;
			width: 94%;
			text-align: center;
			& > .link-list__link {
				margin: 0 auto;
				max-width: 90%;
				display: block;
				text-decoration: none;
				@include mq($from: screen-sm){
					max-width: 60%;
				}
				svg {
					z-index: 2;
					margin: 0 auto;
					position: relative;
					font-size: em(60);
					display: block;
					margin-bottom: em(15, 60);
					transition: fill 0.2s;
				}
				&:hover {
					svg {
						fill: $color-white;
					}
					&:after {
						opacity: 1;
					}
				}
				&:after {
					content: '';
					display: block;
					//background-color: rgba($color-green, .1);
					background-color: rgba($color-green, 1);
					border-radius: 6px;
					position: absolute;
					top: em(-10);
					left: 50%;
					transform: translateX(-50%);
					z-index: 1;
					width: em(80);
					height: em(80);
					transition: all 0.2s;
					opacity: 0;
				}
			}
		}
	}
}