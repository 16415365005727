// Navicons
//
// Your standard button suitable for clicking. [link](http://google.dk)
//
// Markup: <a href="#" class="navicon {{modifier_class}}"><span class="navicon__icon"></span></a>
//
// .navicon--chevron   - chevron
// .navicon--chevron.s--active   - chevron
//
// Style guide: 1.5

//Variables
$base : 1rem;
$color : $color-black;
$button-size: 1rem;
$transition: .2s;

//Mixin
@mixin line {
	display: inline-block;
	width: 100%;
	height: 1/16 + em;
	background: $color;
	border-radius: 1em/14;
	transition: $transition;
	position: absolute;
	left:0;

}
.navicon {
	padding: .5em 0;
	width: 1em;
	display: inline-block;
}
%chevron_common {
	transform-origin: 2% 50%;
}
.navicon--chevron {
	.navicon__icon {
		display: block;
		height: rem(1);
		background: transparent;
		position: relative;
		width: 100%;
		&:before {
			@include line();
			@extend %chevron_common;
			content: '';
			top: 0rem;
			transform: rotate3d(0, 0, 1, 45deg);
			width: 80%;
		}
		&:after {
			@include line();
			@extend %chevron_common;
			content: '';
			width: 80%;
			top: 0rem;
			transform: rotate3d(0, 0, 1, -45deg);
		}
	}
	&.is--active, .is--active & {
		.navicon__icon::before {
			width: 100%;
			transform-origin: 50% 50%;

		}
		.navicon__icon::after {
			transform-origin: 50% 50%;
			width: 100%;

		}
	}
}
.navicon--close {
	.navicon__icon {
		display: block;
		height: rem(1);
		background: transparent;
		position: relative;
		width: 100%;
		&:before {
			@include line();
			@extend %chevron_common;
			content: '';
			top: 0rem;
			width: 100%;
			transform: rotate3d(0, 0, 1, -45deg);
			transform-origin: 50% 50%;
		}
		&:after {
			@include line();
			@extend %chevron_common;
			content: '';
			top: 0rem;
			width: 100%;
			transform: rotate3d(0, 0, 1, 45deg);
			transform-origin: 50% 50%;

		}
	}

}

