// Footer
//
// DOD: done
//
// Icludes the script for Google Translate Widget.
//
// Should be places inside .page-wrapper element.
//
// Markup: ../../templates/prototype/partials/main-footer.hbs
//
// Style guide: 3.4


.footer {
	color: $color-white;
	
	// &__logo,
	// &__address,
	// &__language {
	// 	width: 100%;
	// 	text-align: center;
	// 	@include mq($from: screen-sm){
	// 		width: auto;
	// 	}
	// }
	&__logo {
		padding-bottom: em(12);
		text-align: center;
		@include mq($from: screen-sm){
			padding-bottom: 0;
			text-align: left;
		}
	}
	&__address {
		padding-top: em(5, 16);
		text-align: center;
		color: $color-grey-dark;
		font-size: em(12);
	}
	&__language {
		padding-top: em(12);
		text-align: center;
		@include mq($from: screen-sm){
			padding-top: 0;
			text-align: right;
		}
		.goog-te-gadget {
			// set font face here
			font-family: $roboto;
			.goog-te-gadget-simple {
				padding: 2px 10px;
				border-radius: 3px;
				background-color: lighten($color-blue-darker, 2%);
				border-left: 1px solid rgba($color-border, .2);
				border-top: 1px solid rgba($color-border, .2);
				border-bottom: 1px solid rgba($color-border, .2);
				border-right: 1px solid rgba($color-border, .2);
				font-size: 14px;
				color: $color-white;
				display: inline-block;
				padding-top: 2px;
				padding-bottom: 3px;
				cursor: pointer;
				zoom: 1;
				img.goog-te-gadget-icon {
					// -webkit-filter: grayscale(1);
					// filter: grayscale(1);
					// border-radius: 2px;
					// opacity: 0.8;
					margin-top: 4px;
					background-image: url(/static/media/google-g.svg) !important;
					background-repeat: no-repeat;
					background-position: 0 0 !important;
				}
				a.goog-te-menu-value {
					color: $color-white;
					font-weight: 200;
					span:nth-last-of-type(1) {
						color: $color-white !important;
						font-size: 8px;
					}
					span:nth-last-of-type(2) {
						display: none;
					}
				}
			}
			&:hover {
				.goog-te-gadget-simple {
					border: 1px solid rgba($color-white, 0.6);
				}
			}
		}
	}
}