

// Utility function — you should never need to modify this
@function gridsystem-width($columns:$columns) {
  @return ($column-width * $columns) + ($gutter-width * $columns);
}
@function calc-width($width, $gutter, $col) {
	@return ($width*(($gutter + gridsystem-width($col))/gridsystem-width($col)));
}
@function calc-col-width($index, $width, $gutter, $col, $grid-total-width: $total-width, $grid-columns: $columns) {
	@return $grid-total-width*(((($gutter+$width)*$index)-$gutter-width) / gridsystem-width($grid-columns));
}
// Set $total-width to 100% for a fluid layout
$total-width: 100%;

//////////
// GRID //
//////////
@mixin row($columns:$columns) {
	@include clearfix();
	display: block;
	margin-left: $gutter-width/-2;
	margin-right: $gutter-width/-2;
	>*{
		display: inline;
		float: left;
		box-sizing: border-box;
		padding-left: $gutter-width/2;
		padding-right: $gutter-width/2;
	}
}
@mixin column($x,$columns:$columns) {
	width: percentage(($x / $columns));

}
@mixin offset($offset:1, $columns:$columns) {
	margin-left: percentage(($offset / $columns));
}
@mixin pull($offset:1, $columns: $columns) {
	right: percentage(($offset / $columns));
}
@mixin pull($offset:1, $columns: $columns) {
	left: percentage(($offset / $columns));
}


@mixin make-grid-col($index, $type) {
	@if ($type == width) and ($index > 0) {
    	@for $i from 1 through $grid-columns {
			.col-xs--#{$i} {
				@include mq($until: 'screen-xs') {
	                @include column($i);
				}
	        }
	        .col-sm--#{$i} {
				@include mq($from: 'screen-xs') {
	                @include column($i);
				}
	        }
	        .col-md--#{$i} {
				@include mq($from: 'screen-sm') {
	                @include column($i);
				}
	        }
	        .col-lg--#{$i} {
				@include mq($from: 'screen-md') {
	                @include column($i);
				}
	        }
    	}
  	}
 //  	@if ($type == push) and ($index > 0) {
 //    	.col-#{$class}-push-#{$index} {
 //    		left: percentage(($index / $grid-columns));
 //    }
 //  	}
 //  	@if ($type == push) and ($index == 0) {
 //    	.col-#{$class}-push-0 {
 //      		left: auto;
 //    	}
 //  	}
 //  	@if ($type == pull) and ($index > 0) {
 //    	.col-#{$class}-pull-#{$index} {
 //      		right: percentage(($index / $grid-columns));
 //    	}
 //  	}
 //  	@if ($type == pull) and ($index == 0) {
 //    	.col-#{$class}-pull-0 {
 //      		right: auto;
 //   	 }
 //  	}
	// @if ($type == offset) {
 //    	.col-#{$class}--#{$index} {
	// 		@include mq($until: screen-#{$class}) {
 //                @include offset($index);
	// 		}
 //        }
 //    }
}
@mixin loop-grid-col($columns, $class, $type) {
	@for $i from 1 through $columns {
		@include make-grid-col($i, $class, $type)
	}
}

@mixin make-grid($class) {
  @include loop-grid-col($grid-columns, $class, width);
  @include loop-grid-col($grid-columns, $class, push);
  @include loop-grid-col($grid-columns, $class, offset);
}

@mixin push($offset:1) {
	margin-left: $total-width*((($gutter-width+$column-width)*$offset) / gridsystem-width($columns)) + $total-width*(($gutter-width*.5)/gridsystem-width($columns));
}
@mixin pull($offset:1) {
	margin-right: $total-width*((($gutter-width+$column-width)*$offset) / gridsystem-width($columns)) + $total-width*(($gutter-width*.5)/gridsystem-width($columns));
}