// Forms in general
//
// All forms-items have 100% width so it's allways the context who decide the width of each form-item.
//
// Markup: ../../templates/prototype/partials/components/forms.hbs
//
// Style guide: 7.1

//Variables
$form-label-font-size: 16;
$form-indent: $space-xlarge;
$form-select-border-right: 43;
$form-border-color: $color-grey;
$form-border-color-active: $color-grey-dark;


//Globals resets
::-webkit-input-placeholder {
	transition: opacity .2s;
	line-height:1em;
	color: $color-grey-dark;
}
:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px white inset;
}
:-moz-placeholder {
	transition: opacity .2s;
	line-height:1em;
	color: $color-grey-dark;
}

::-moz-placeholder {
	transition: opacity .2s;
	line-height:1em;
	color: $color-grey-dark;
}

:-ms-input-placeholder {
	transition: opacity .2s;
	line-height:1em;
	color: $color-grey-dark;
}

[placeholder]:focus::-webkit-input-placeholder {
	opacity: .3;
}

[placeholder]:focus:-moz-placeholder {
	opacity: .3;
}

[placeholder]:focus::-moz-placeholder {
	opacity: .3;
}

[placeholder]:focus:-ms-input-placeholder {
	opacity: .3;
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}


//Extends
%form-item {
	transition: border .2s;
	-webkit-appearance: none;
	-moz-appearance: none;
	-o-appearance: none;
	background-color: $color-white;
	width: 100%;
	box-sizing: border-box;
	font: normal em(14)/1.2 $roboto;
	margin: 0;
	padding: em(12, 14);
	border: rem(1) solid $form-border-color;
	border-radius: em(4);
	min-height: em(35);
	color: $color-black;
	position: relative;
	// &:hover, &:focus, &:active {
	// 	outline: none;
	// }
	&:focus,
	&:active {
		//border-color: $form-border-color-active;
		border-color: $color-green;
		color: $color-black;
	}
	.is--hidden & {
		//visibility: hidden;
	}
}

%form-border {
	content: '';
	width: em(0);
	height: em(41);
	display: inline;
	position: absolute;
	top: 0;
	right: em($form-select-border-right);
	//border-left: rem(1) solid $color-grey-10;
}

%state-icon {
	content: '';
	display: block;
	width: em(17);
	height: em(17);
	background-size: 70%;
	background-repeat: no-repeat;
	background-position: center center;
	border-radius: 50%;
	position: absolute;
	top: em(41);
	right: em(20);
	z-index: 1;

}

//Custom styling
.form {
	position: relative;
	counter-reset: formgroup;
}

.form__group {
	.form__legend {
		font: normal em(18)/1.2 $roboto;
		color: $color-black;
		position: relative;
		margin-bottom: em($space-small);
	}
	&.form__group--indent {
		@include mq($from:screen-md) {
			margin-left: em($form-indent);
		}
	}

}
.form__field {
	position: relative;
	&+.form__field {
		margin-top: em($space-small);
	}
	&.collapse--bottom {
		margin-bottom: 0;
	}
	&~.text--indent {
		margin-top: em(-$space-small);
	}
	&--text {
		&.is--ok {
			// &:after {
			// 	@extend %state-icon;
			// 	background-image: url(/static/media/validation-check.svg);
			// 	background-color: $color-green;
			// }
		}
		&.is--error {
			// &:before {
			// 	@extend %state-icon;
			// 	background-image: url(/static/media/validation-error.svg);
			// 	background-color: $color-red;
			// }
		}
		&.form__field--inline {
			&:after, &:before {
				top: em(13);
			}
		}
	}
	&--no-label {
		@include mq($from:screen-md) {

		}
	}
	.form__label__text  {
		>strong {
			font-weight: bold;
		}
	}
	&--indented {
		&:last-child {
			margin-bottom: em($space-xsmall);
		}
		.form__label {
			transition: all .2s linear;
			color: $color-grey-light;
			font-weight: 200;
			font-size: em($form-label-font-size);
			margin-bottom: em($space-small);
		}

		.readMore {
			opacity: .3;
			transition: opacity .1s linear;
		}
	}
	&--indented__container {
		margin-left: em($space-xlarge);
		//margin-top: em(-12);

		>.form__field {
			margin-bottom: em(5);
		}
	}
	&.is--checked {
		&+.form__field--indented__container {
			.form__label {
				color: $color-black;
			}
		}
	}
	&--inline {
		display: inline-block;
		vertical-align: bottom;
		.form__item {
			display: block;
		}
		.button {
			line-height: em(41, $form-label-font-size);
		}
		&.form__field--voucher {
			vertical-align: middle;
		}
	}
}

.form__field--icon {
	>.svg-icon {
		float: left;
	}
	>.form__label {
		display: block;
		margin-left: em(70);
	}
}
.form__label {
	position: relative;
	display: block;
	font-size: em($form-label-font-size);
	line-height: 1.4;
	color: $color-black;

	>.text {
		text-decoration: underline;
	}
	>.form__label__text {
		.form--required & {
			&:after {
				content: ' *';
			}
		}

	}
	>.tooltip {
    display: inline-block;
    margin-left: em(7);

	}
	@include mq($from:screen-md) {
		line-height: 2.1;
	}

	.readMore {
		position: relative;
		left: em($space-xsmall);
	}
}

.form__label__img {
	max-height: em(22, 14);
}

.form__field--with-icon {
	margin-bottom: 50px;    // Esben det virker ikke
}

.form__notice {
	display: none;
	.is--error & {
		display: block;
		color: $color-red;
		font-size: em(14);
		line-height: 1.7;
	}
}


// Input Text
//
// Input text description
//
// Markup: ../../templates/prototype/partials/components/form-text.hbs
//
// .is--error   - Highlights when there is a validation error.
// .is--ok   - After a success validation.
// .form--required		- Required field
// .form__field--row		- transforms form field to inline view
//
// Style guide 7.2

.form__field--text {
	>.form__item {
		transition: opacity .4s;
		@extend %form-item;
		&::-ms-clear, &::-ms-reveal {
			display: none;
			width: 0;
			height: 0;
		}
		&[disabled] {
			opacity: .4;
		}
		&[readonly] {
			background-color: $color-grey-light;
		}
	}
	.form__label__text {
		font-weight: bold;
	}
	&.form__field--row {
		>.form__item {
			display: inline-block;
			width: inherit;
		}
		>.form__label {
			display: inline-block;
			padding-right: em(30, 14);
			>.tooltip {
				right: em(5, 14);
			}
		}
	}
	&.is--error {
		>.form__item {
			border-color: $color-red;
		}
	}
}

// input:digits add currency
.form__field--digits {
	&:after {
		content: attr(data-currency-type);
		position: absolute;
		display: block;
		top: em(19);
		right: em(40);
	}
	.form__item {
		padding-right: em(30);
	}
}


// Input file
//
// Input file description
//
// Markup: ../../templates/prototype/partials/components/form-file.hbs
//
// .is--error   		- Highlights when there is a validation error.
// .is--ok   			- After a success validation.
// .form--required		- Required field
// .form__field--row	- transforms form field to inline view
//
// Style guide 7.2.1

.form__field--file {
	>.form__item {
		transition: opacity .4s;
		@extend %form-item;
		padding: em(12, 14);
		padding: 0;
		border: rem(1) solid transparent;
		//visibility: hidden;
		width: auto;
		min-width: em(200);
		padding-left: 60px;
		padding-top: 10px;

		&::-ms-clear,
		&::-ms-reveal {
			display: none;
			width: 0;
			height: 0;
		}
		&[disabled] {
			opacity: .4;
		}
		&[readonly] {
			background-color: $color-grey-light;
		}
		&::-webkit-file-upload-button {
			visibility: hidden;
		}
		&:focus,
		&:active {
			border-color: transparent;
			//color: $color-black;
		}
		&:before {
			//content: 'Vælg fil(er)';
			content: attr(data-title);
			display: inline-block;
			background-color: $color-green;
			color: white;
			border: 0;
			border-radius: rem(3);
			padding: em(12, 14) em(22, 14);
			margin-left: -60px;
			margin-top: -10px;
			outline: none;
			white-space: nowrap;
			-webkit-user-select: none;
			cursor: pointer;
			transition: background-color .25s ease;
		}
		&:hover::before {
			//border-color: black;
			background-color: darken($color-green, 3%);
		}
		&:active::before {
			background-color: darken($color-green, 3%);
		}
	}
	.form__label__text {
		font-weight: bold;
	}
	&.form__field--row {
		>.form__item {
			display: inline-block;
			width: inherit;
		}
		>.form__label {
			display: inline-block;
			padding-right: em(30, 14);
			>.tooltip {
				right: em(5, 14);
			}
		}
	}
	&.is--error {
		>.form__item {
			border-color: $color-red;
		}
	}
	
}


// Select
//
// Select description
//
// Markup: ../../templates/prototype/partials/components/form-select.hbs
//
// .is--error   - Highlights when there is a validation error.
// .is--ok   - After a success validation.
// .form--required		- Required field
//
// Style guide 7.3

.form__field--select {
	.form__container {
		position: relative;
		
		// &:before {
		// 	display: block;
		// 	content: "\25BE";
		// 	position: absolute;

		// 	top:8px;
		// 	right:10px;
		// 	z-index: 1;
		// }
	}
	&.is--focus {
		.form__container {
			&:after {
				border-left-color: $color-grey-light;
			}
		}
	}
	.form__item {
		@extend %form-item;
		padding-right: em($form-select-border-right, $form-label-font-size);
		//background-image: url(/static/media/arrow-down.svg);
		//background-repeat: no-repeat;
		//background-position: calc(100% - 15px) center;
		text-indent: .01px;

		font: normal 1em/1 $roboto;
		background-image: url(/static/media/dropdown_arrow.svg);
		background-position: center right 10px;
		background-repeat: no-repeat;

		&:-moz-focusring {
			color: transparent;
			text-shadow: 0 0 0 $color-black;
		}
		&::-ms-expand {
			display: none;
		}
		&:focus {
			outline:0 !important;
			border-color: $color-black;
		}

	}

	.form__label {
		font-weight: bold;
	}

	&.is--error {
		.form__item {
			border-color: $color-red;
		}
		.form__container {
			&:after {
				border-color: $color-red;
			}
		}

	}
}


// Textarea
//
// Textarea description
//
// Markup: ../../templates/prototype/partials/components/form-textarea.hbs
//
// .is--error   - Highlights when there is a validation error.
// .is--ok   - After a success validation.
// .form--required		- Required field
//
// Style guide 7.4

.form__field--textarea {
	.form__item {
		@extend %form-item;
		transition: min-height .2s ease-in-out;
		display: block;
		width: 100%;
		min-height: em(100);
		resize: none;
		&:focus {
			min-height: em(200);
		}
	}

	.form__label {
		font-weight: bold;
	}

	&.is--error {
		>.form__item {
			border-color: $color-red;
		}
	}
}

.form__field--date {
	.form__item {
		@extend %form-item;
		background: url(/static/media/calendar.svg) no-repeat center center;
		background-repeat: no-repeat;
		background-position: calc(100% - 15px) center;
	}
}


// Radio
//
// Radio-buttons
//
// Markup: ../../templates/prototype/partials/components/form-radio.hbs
//
// .is--error   - Highlights when there is a validation error.
// .is--ok   - After a success validation.
// .form--required		- Required field
// .is--checked
//
// Style guide: 7.6

.form__field--radio {
	.form__item {
		vertical-align: top;
		margin-top: em(9);

		@include mq($until:screen-md) {
			float: left;
			margin-top: em(5);
		}
	}
	.form__label {
		position: relative;
		//font-weight: 200;
		&--sub {
			display: block;
			text-transform: lowercase;
		}
	}

	.form__label__text {
		display: inline;
		//max-width: calc(100% - 26px);
		margin-left: em(3);

		@include mq($until:screen-md) {
			margin-left: em(10);
			//max-width: calc(100% - 50px);
		}
	}
	&.invert {
		.form__item {
			left: inherit;
			right: 0;
		}
		.form__label {
			padding: 0 em($form-indent, $form-label-font-size) 0 0;
		}
	}
}


// Checkbox
//
// Checkboxes
//
// Markup: ../../templates/prototype/partials/components/form-checkbox.hbs
//
// .is--error   		- Highlights when there is a validation error.
// .is--ok   			- After a success validation.
// .form--required		- Required field
//
// Style guide: 7.7

.form__field--checkbox {

	.form__item {
		vertical-align: top;
		margin-top: em(10);

		@include mq($until:screen-md) {
			float: left;
			margin-top: em(5);
		}
	}
	.form__label {
		position: relative;
		//font-weight: 200;
	}
	.form__label__text {
		display: inline-block;
		max-width: calc(100% - 30px);
		margin-left: em(3);

		@include mq($until:screen-md) {
			margin-left: em(10);
			max-width: calc(100% - 50px);
		}
	}

	&.invert {
		.form__item {
			left: inherit;
			right: 0;
		}
		.form__label {
			padding: 0 em($space-xlarge, $form-label-font-size) 0 0;
		}
	}
}




.form__label--media {
	.form__label__img {
		@include vertical-align();
		float: left;
		display: block;

	}
	.form__label__text {
		display: block;
		margin-left: em(45, 14);
		line-height: 1.2;
		>.t--small {
			display: block;
		}
	}
}


.form__sub-total {
	padding-top: em($space-xlarge);
	border-bottom: rem(2) solid $color-black;
	font-weight: 500;
	>.form__sub-total__price {
		float: right;
		font-weight: 500;
	}
}

// Form Validation
//
// Validation is best shown on the script page. [Script documentation](/prototype/scripts.html)
//
// Markup: <input type="text" data-validate="text:1:300">
//
// Style guide: 7.8

