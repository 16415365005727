// Decks
//
// DOD: done
//
// Decks are primary content containers and can be used everywhere. Decks normally lives with a "width normal" (w--normal) container as the first child.
//
// Markup: ../../templates/prototype/partials/components/deck.hbs
//
// .collapse--top			- Collapses padding in top
// .collapse--bottom		- Collapses padding in bottom
// .deck--white				- Deck with white background. Hence the html-tag, for various reasons, have a "darker-blue" background color, any deck needs a specifed background color.
// .deck--color-bg			- Deck with "color-bg" background, which is speficied in colors. (light grey)
// .deck--bgimg				- Deck with full width background image. URL should be specified in style attribute on the container, eg. style="background-image: url();".
//
// Style guide: 3.1


// Deck with service-link beside deck title
//
// DOD: done
//
// A service-link would be relevant when an incomplete list of anything, gets a link to the full list.
//
// Markup: ../../templates/prototype/partials/components/deck-w-servicelink.hbs
//
// Style guide: 3.1.1

.deck {
	position: relative;
	width: 100%;
	.w--narrow,
	.w--normal,
	.w--wide {
		position: relative;
		padding: rem($gutter-thick) 0;

    }
	&.half-padding {
		.w--narrow,
		.w--normal,
		.w--wide {
	        padding: rem($gutter-normal) 0;
	    }
	}
	&.half-padding-bottom {
		.w--narrow,
		.w--normal,
		.w--wide {
	        padding-bottom: rem($gutter-normal);
	    }
	}
	&.half-padding-top {
		.w--narrow,
		.w--normal,
		.w--wide {
	        padding-top: rem($gutter-normal);
	    }
	}
	&.double-padding-top {
		.w--narrow,
		.w--normal,
		.w--wide {
			position: relative;
			padding: rem($gutter-thick*1.5) 0 rem($gutter-thick) 0;

	    }
	}
	&.collapse--top {
	    .w--narrow,
		.w--normal,
		.w--wide {
	        padding-top: 0;
	    }
	}
	&.collapse--bottom {
	    .w--narrow,
		.w--normal,
		.w--wide {
	        padding-bottom: 0;
	    }
	}
	&.deck--with-tools {
		.w--narrow,
		.w--normal,
		.w--wide {
	        padding-top: rem($gutter-normal);
	    }
		.deck__tools {
			margin-bottom: rem($space-large);
		}
	}
	&.deck--aside {
		z-index: 2;
		.w--normal {
			padding: 0;
		}
	}
	&__tools__title {
		font-size: em(22);
		font-weight: bold;
		color: $color-grey-light;
	}
	&__servicelink {
		margin-top: rem($space-medium);
		display: block;
		text-align: center;
		@include mq($from: screen-sm){
			margin-top: 0;
			position: absolute;
			top: rem(70);
			right: 0;
		}
		// margin-top: em(24); // TODO - depends on deck title font size
		// float: right;
		// min-width: em(20);
		& > a {
			font-size: em(15);
			text-decoration: underline;
			&:hover {
				text-decoration: none;
			}
		}
	}
	&.w--page-header-box + .deck {
		// double padding top
		.w--normal {
			padding: rem($gutter-thick*1.5) 0 rem($gutter-thick) 0;
		}
		&.collapse-bottom .w--normal {
			padding: rem($gutter-thick*1.5) 0 0 0;
		}
	}
	&.deck--white + .deck--white {
		.w--normal {
			padding-top: 0;
		}
	}
	&.deck--color-bg + .deck--color-bg {
		.w--normal {
			padding-top: 0;
		}
	}
}

.deck__title {
	@extend %h1-klavika;
	margin-bottom: rem($space-small);

	&--centered {
		text-align: center;
	 	& > a {
	 		text-align: center;
	 	}
	}

	&--white {
		color: $color-white;
	}

	&--sub {
		font-family: $roboto;
	}
	& > a {
		@extend %h2-klavika;
		margin-bottom: rem($space-small);
		color: $color-black;
	}
}
h1.deck__title {
	@extend %h1-klavika;
}
h2.deck__title {
	@extend %h2-klavika;
}
.deck__title + p {
	margin-bottom: rem(20);
}
.deck__title > .deck__subtitle {
	text-align: center;
	margin-bottom: rem($space-medium);
}

.deck--white {
	background: $color-white;
}
.deck--grey-light {
	background: $color-grey-light;
}
.deck--color-bg {
	background: $color-bg;
}
.deck--green {
	background: $color-green;
}
.deck--blue-dark {
	background: $color-blue-dark;
}
.deck--blue-darker {
	background: $color-blue-darker;
}

.deck--bgimg { // mainly specific for the "help and contact"-page
	//background-image: url(attr);
	background-repeat: no-repeat;
	background-position: top center;
    background-size: 120% auto;
    //min-height: 450px;
    @include mq($from: screen-md){
    	background-size: cover;
    }
    .w--narrow {
    	@include mq($until: screen-md){
    		max-width: 70%;
    	}
    	margin: 0 auto;
    	.page-header {
    		max-width: 70%;
    		display: block;
    		margin: 0 auto;
    	}
    }
}

.deck--slideshow {
	// reference to slideshow.scss
}


// below are fixes for the floating deck structures in articles
.components-container > .deck:last-child > .w--normal {
    padding-bottom: rem($gutter-thick);
}
.deck--aside:last-child .image-box--inline {
   float: none;
   margin-left: 0;
   margin-right: 0;
}
//.components-container > .deck--aside:nth-child(2),

.components-container {
	& .deck--aside:nth-child(2) .w--normal {
		//padding-top: rem($gutter-thick * 1.5);
		padding: 0 !important;
	}
	& .deck.w--page-header-box + .deck--aside {
		aside {
			margin-top: rem($gutter-thick * 1.5);
		}
	}
	& .deck.w--page-header-box + .deck--aside + .deck {
		padding-top: rem($gutter-thick * 1.5);
	}

	& .deck:first-child:not(.w--page-header-box) + .deck--aside:nth-child(2) + .deck .w--normal {
		padding-top: rem($gutter-thick);
	}
	& .deck:first-child:not(.w--page-header-box) + .deck--aside:nth-child(2) aside {
		margin-top: rem($gutter-thick);
	}
}

.components-container > .deck--aside:first-child {
    //padding-top: rem($gutter-thick);
}
// .components-container > .deck--aside:first-child {
//     //padding-top: rem($gutter-thick);
//     //float: none;
  
//     //margin-top: 0;
// }
// .components-container > .deck--aside {
//     //padding-top: rem($gutter-thick);
//     //float: none;
    
//     //margin-top: 20px;
// }
.components-container > .deck--aside:last-child {
    //padding-top: rem($gutter-thick);
    float: none;
    
    //margin-top: 0;

    .image-box--inline, .facts-box, .info-box {
    	float: none;
    	margin-left: 0;
    }
}
.components-container > .deck--aside + .deck .rich-text p:first-child { // fixing floated element when element should align with text-top
    margin-top: 0;
}

//.deck--aside  .image-box--inline + .deck  .rich-text { float: left / right }

