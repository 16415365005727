// Box oriented helper classes
//
// Helper classes that gives different utility functions. All clases are prefix with the first letter or state of the function. ex. a--left stands for align.
// > `v` Visibility
// >
// > `f` Floats
// >
// > `b` Borders
//
// Markup: <div class="c--blue {{modifier_class}}">lorem ipsum</div>
//
// .v--mobile-only		- only visible on mobile
// .v--desktop-only		- onlu visible on desktop
// .v--sr-only			- only visible for screenreaders
// .b--bottom 			- border bottom
// .b--top 				- border top
// .clearfix			- fixing clear problems on floats
//
// Style guide: 6.1

.clearfix {
	@include clearfix();
}
// width limits for pages
.w--normal {
	max-width: em($max-page-width);
	margin: 0 em($gutter-mobile);
	
	@include mq($from: screen-sm, $until: $max-page-width + ($gutter-normal * 2)) {
		margin: 0 em($gutter-normal);
	}
	@include mq($from: $max-page-width + ($gutter-normal * 2)) {
		margin: 0 auto;
	}
	
	// @include mq($from: screen-lg) {
	// 	margin: 0 auto;
	// }
	// @include mq($from:screen-xxl) {
	// 	max-width: em(1300);
	// }
	// @include mq($from:screen-xxl) {
	// 	max-width: em(1480);
	// }
}

.w--narrow {
	max-width: em($max-page-width-narrow);
	margin: 0 em($gutter-mobile);
	@include mq($from: screen-sm, $until: $max-page-width + ($gutter-normal * 2)) {
		margin: 0 em($gutter-normal);
	}
	@include mq($from: $max-page-width-narrow + ($gutter-normal * 2)) {
		margin: 0 auto;
	}
}
.w--wide {
	max-width: em($max-page-width-wide);
	margin: 0 em($gutter-mobile);
	@include mq($from: screen-sm, $until: $max-page-width + ($gutter-normal * 2)) {
		margin: 0 em($gutter-normal);
	}
	@include mq($from: $max-page-width-wide + ($gutter-normal * 2)) {
		margin: 0 auto;
	}
}
.w--form {
	width: em($max-form-width);
}
.c--narrow {
	max-width: em(600);
}
.c--indent {
	margin-left: 0;
	@include mq($from: screen-md){
		margin-left: em($main-content-margin-left);
	}
}
//Visibility
.v--mobile-only {
	@include mq($from: screen-md) {
		display: none !important;
	}
}
.v--desktop-only {
	@include mq($until: screen-md) {
		display: none !important;
	}
}
@mixin screen-reader-only(){
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip: rect(0,0,0,0);
	border: 0;
}
.v--sr-only { //used to hide labels that should still be available for screenreaders
	@include screen-reader-only();
}

// States
//
// States is strongly inspired by SMACSS and always prefixed with ´is--`
//
// Markup: <div class="{{modifier_class}}"></div>
//
// .is--ok			- Validation state for a validated form field
// .is--error		- Validation state for an error validated form field
// .is--hidden		- Hidden for all screens and screenreaders
//
// Style guide: 6.3

.is--hidden {
	display: none;
}
// [aria-hidden="true"] {
// 	visibility: hidden;
// }


.is-calculation-height {
	visibility: hidden;
	position: absolute;
	width: 0;
}

.gutter--small {
	margin-bottom: em($space-small);
}

.gutter--medium {
	margin-bottom: em($space-medium);
}

.gutter--large {
	margin-bottom: em($space-large);
}

// text colors
.tc--light {
	color: $color-white;
}


// text-alignment
.a--center {
	text-align: center !important;
}

.a--left {
	text-align: left !important;
}

.a--right {
	text-align: right !important;
}
.va--middle {
	@include vertical-align();
}
.f--right {
	float: right;
}
.f--left {
	float: left;
}
// borders
.b--top {
	border-top: rem(1) solid $color-border;
}
.b--bottom {
	border-bottom: rem(1) solid $color-border;
}

// Text oriented helper classes
//
// Helper classes that gives different utility functions. All clases are prefix with the first letter or state of the function. ex. a--left stands for align.
// > `a` Align
// >
// > `v` Visibility
// >
// > `f` Floats
// >
// > `t` Text styles
//
// Markup: <div class="c--blue {{modifier_class}}">lorem ipsum</div>
//
// .t--italic		- italic text
// .t--bold			- bold text
// .t--small		- small text
// .a--left    		- align text left
// .a--center    	- align text left
// .a--right    	- align text right
//
// Style guide: 6.2

%t--small {
	font-size: em(12);
}
.t--small {
	@extend %t--small;
}
.t--strike {
	text-decoration: line-through;
}

.t--bold {
	font-weight: bold;
}
