// CSS animations
//
// CSS animation library
//
// Markup: <div class="{{modifier_class}}">test</div>
//
// .animation--fly-in   - make the element fly in from the bottom
// .shiny   - Do not press this big, shiny, red button.
//
// Style guide: components.button

@mixin animation($name, $count, $timing, $duration, $fillmode){
	%animation--#{$name} {
		$timing: "ease-in" !default;
		$duration: .2 !default;
		animation-fill-mode: $fillmode;
		animation-name: $name;
		animation-iteration-count: $count;
		animation-timing-function: $timing;
		animation-duration: #{$duration}s;
	}
	.animation--#{$name} {
	  @extend %animation--#{$name};
	}
}

//Fly up
@include animation(fly-up, 1, "ease-in", .5, forwards);
@keyframes fly-up {
	0% {
		//transform: translateY(100%);
		transform: translate3d(0, 100%, 0);
	}
	100% {
		//transform: translateY(0);
		transform: translate3d(0, 0%, 0);
	}
}
//fly down
@include animation(fly-down, 1, "ease-in-out", .4, forwards);
@keyframes fly-down {
	0% {
		//transform: translateY(0%);
		transform: translate3d(0, 0%, 0);
	}

	100% {
		//transform: translateY(100%);
		transform: translate3d(0, 100%, 0);
	}
}



%animation--shake {
	animation-name: shake;
	animation-duration: .5s;
	animation-fill-mode: both;
}
.animation--shake {
  @extend %animation--shake;
}
@keyframes shake {
  0%, 100% {
	transform: translate3d(0, 0, 0);
  }

  15%, 45%, 70%, 90% {
	transform: translate3d(-3px, 0, 0);
  }

  30%, 60%, 80%, 95% {
	transform: translate3d(3px, 0, 0);
  }
}
%animation--fade-in {
	animation-name: fadeIn;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: .8s;
}
.animation--fade-in {
  @extend %animation--fade-in;
}
@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
%animation--collapse-fade-out {
	animation-name: collapseFadeOut;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
  animation-duration: .8s;
}
.animation--collapse-fade-out {
  @extend %animation--collapse-fade-out;
  overflow: hidden;
}
@keyframes collapseFadeOut {
	0% {
		opacity: 1;
	}
	40% {
		opacity: 0;
		max-height: 400px;
	}
	100% {
		opacity: 0;
		max-height: 0;
	}
}
%animation--fly-left {
	animation-name: flyLeft;
	animation-iteration-count: 1;
	animation-timing-function: ease-in-out;
	animation-duration: .8s;
}
.animation--fly-left {
	@extend %animation--fly-left;
}
@keyframes flyLeft {
	0% {
		transform: translateX(0);
	}

	100% {
		transform: translateX(100%);
	}
}


%animation--attention {
	animation-name: attention;
	animation-iteration-count: 1;
	animation-timing-function: ease-in-out;
	animation-duration: .4s;
}
.animation--attention {
   @extend %animation--attention;
}
@keyframes attention {
  0% {
	transform: translateX(0);
  }

  50% {
	transform: translateX(30%);
  }
  100% {
	transform: translateX(0);
  }
}

%animation--heartbeat {
	animation-name: heartbeat;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-duration: 2s;
}
.animation--heartbeat {
   @extend %animation--heartbeat;
}
@keyframes heartbeat {
  0% {
	transform: scale(1);
  }

  50% {
	transform: scale(.5);
  }
  100% {
	transform: translateX(1);
  }
}


