// Modalbox
//
// Markup: Modalbox is best demonstrated at the <a href="/prototype/scripts.html">[scripts page]</a>
//
// Style guide: 5.2


.modal {
	transition: transform .2s;
	position: relative;
	width: 95%;
	background-color: $color-white;
	margin-left: auto;
	margin-right: auto;
	padding: em(60) em(25) em(25);
	box-sizing: border-box;
	transform: scale(0);

	.is--active & {
		transition: transform .2s;
		transform: scale(1);
	}
	&__close {
		position: absolute;
		right: em(15);
		top: em(15);
		cursor: pointer;
		>.navicon {
			width: em(18);
			>.navicon__icon {
				font-size: 2em;
			}
			.no--csstransforms &{
				background-color: red;
			}
		}
		&__text {
			display: block;
			font-size: em(13);
			color: $color-grey;
			@include mq($from:screen-md) {
				@include screen-readers-only();
			}
		}
	}
	&.modal--large {
		width: 95%;
	}
	@include mq($from:screen-sm) {
		width: em($modal-small);

	}
	@include mq($from:screen-md) {
		padding: em(60);
	}
	@include mq($from:screen-lg) {
		&.modal--large {
			width: em($modal-large);
		}
	}
	&.modal--gallery {
		width: auto;
		max-width: 85%;
		padding: em(120) 0 0;
		vertical-align: top;
		.modal__close {
			position: absolute;
			right: calc(50% - 25px);
			top: em(64);
			width: em(36);
			height: em(36);
			cursor: pointer;
			text-align: center;
			>.navicon {
				margin-top: em(5);
				width: em(36);
				>.navicon__icon {
					font-size: 2em;
					&:before, &:after {
						background: $color-black;
					}
				}
				.no--csstransforms &{
					background-color: red;
				}
			}
			&:hover {
				>.navicon__icon {

				}
			}

			@include mq($from:screen-md) {
				right: em(-38);
				top: em(80);
			}

		}
	}
	&.bg--transparent {
		background-color: transparent;
	}

}

.modal__header {
	border-bottom: rem(1) solid $color-border;
	margin-bottom: em($space-small);
	>h3 {
		@extend %h3;
	}
	>.modal__subheader {
		font: normal em(13)/1.2 $roboto;
	}
}
.modal__content {
	.notification {
		margin-bottom: em($space-medium);
	}


	.product-line {
		.product-line__image {
			@include mq($until:screen-sm) {
				width: inherit;
				float: none;
			}
		}
		.product-line__body {
			@include mq($until:screen-sm) {
				margin: em($space-large) 0 0;
			}

		}
		&__blocklink {
			display: block;
		}
		.product-line__item--price {
			text-align: center;
			.button {
				width: 100%;
				margin-bottom: em(5);
			}
		}

	}
}

.modal__footer {
	text-align: right;
	>.button {
		min-width: inherit;
		width: inherit;
	}
}

.modal__content .basket {
	margin-bottom:0;
	.product__image > .image-holder {
		max-width: em(50);
	}
}
