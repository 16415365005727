
//inline grid
$grid-gutter: 25 !default;
$grid-columns: 12 !default;

// floating grid
$column-width: 60px !default;
$gutter-width: 25px !default;
$columns: $grid-columns !default;

// Grid - Real-life example
//
// DOD: done
//
// Traditional 12 column floating grid. Gutters are percentage and will be relative to it's context. (IE7+).
// * Classes below are in context of ex. "col-xs--6" or "col-md--2", and can be combinded to target any of the media queries.
//
// Changelog:
// * 16-11-15 - Changed margin to padding
//
// Markup: ../../templates/styleguide/components/grid-float--for-documentation.hbs
//
// Style guide: 2.1


// Grid - Media Query example
//
// DOD: done
//
// This grid-example that follows is solely for demonstration purposes!
// The demonstration has seperated breakpoints so the meaning of each class(modifier) makes sense.
//
// Changelog:
// * 16-11-15 - updated examples (no code change)
//
// Markup: ../../templates/styleguide/components/grid-float.hbs
//
// .xs 		- targets elements in xtra-small-mq example (until 566px)
// .sm 		- targets elements in small-mq example (from 567px)
// .md 		- targets elements in medium-mq example (from 768px)
// .lg 		- targets elements in large-mq example (from 1024px)
// .xl 		- targets elements in xtra-large-mq example (from 1200px)
// .xxl 	- targets elements in xtra-xtra-large-mq example (from 1350px)
// .xxxl 	- targets elements in xtra-xtra-xtra-large-mq example (from 1500px)
//
// Style guide: 2.2

.row {
	@include clearfix();
	@include row($grid-columns);
	@for $i from 1 through $grid-columns {
		.col--#{$i} {
			@include column($i);
        }
	}
	@for $i from 1 through $grid-columns {
		.col-xs--#{$i} {
			@include mq($until: 'screen-xs-1') {
                @include column($i);
			}
        }
	}
	@for $i from 1 through $grid-columns { 
        .col-sm--#{$i} {
			@include mq($from: 'screen-xs') {
                @include column($i);
			}
        }
	}
	@for $i from 1 through $grid-columns {
        .col-md--#{$i} {
			@include mq($from: 'screen-sm') {
                @include column($i);
			}
        }
	}
	@for $i from 1 through $grid-columns {
        .col-lg--#{$i} {
			@include mq($from: 'screen-md') {
                @include column($i);
			}
        }
	}
	@for $i from 1 through $grid-columns {
        .col-xl--#{$i} {
			@include mq($from: 'screen-lg') {
                @include column($i);
			}
        }
	}
	@for $i from 1 through $grid-columns {
        .col-xxl--#{$i} {
			@include mq($from: 'screen-xl') {
                @include column($i);
			}
        }
	}
	@for $i from 1 through $grid-columns {
        .col-xxxl--#{$i} {
			@include mq($from: 'screen-xxl') {
                @include column($i);
			}
        }
	}

	@for $i from 1 through $grid-columns {
		.col--push--#{$i} {
			@include push($i);

			@include mq($until:screen-md) {
				@include push(0);
			}
		}
	}

	// offset
	@for $i from 1 through $grid-columns {
		.col-xs-offset--#{$i} {
			@include mq($until: screen-xs-1) {
                @include offset($i);
			}
        }
    }
    @for $i from 1 through $grid-columns {
        .col-sm-offset--#{$i} {
			@include mq($from: 'screen-xs') {
                @include offset($i);
			}
        }
    }
    @for $i from 1 through $grid-columns {
        .col-md-offset--#{$i} {
			@include mq($from: 'screen-sm') {
                @include offset($i);
			}
        }
    }
    @for $i from 1 through $grid-columns {
        .col-lg-offset--#{$i} {
			@include mq($from: 'screen-md') {
                @include offset($i);
			}
        }
    }
}
