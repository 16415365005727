// .row {
// 	padding: em($gutter-normal) 0;
// }
.row--flex {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
	.no-flexbox & {
		@include clearfix();
		display: table;
		width: 100%;
	}
	@include mq($from: screen-md) {
		flex-wrap: nowrap;
	}
	>.row__item {
		-ms-flex: 0 1 auto;
		flex: 0 1 auto;
		margin-bottom: rem($gutter-thin);
		width: 100%;
		.no-flexbox & { //fallback
			display: table-cell;
			vertical-align: middle;
			width: auto;

			&:last-child {
				text-align: right;
				float: right;
			}
		}
		&--no-shrink {
			flex: 0 0 auto;
		}
		&.flexrow--last {
			order: 99;
		}
		@include mq($from:screen-md) {
			margin-bottom: 0;
			&:not(:first-child) {
				margin-left: em($space-medium);
			}
			&.flexrow--last {
				order: unset;
			}

		}
	}
}

