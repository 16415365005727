// Simple Buttons
//
// DOD: done
//
// All buttons in the site are built from the same base class following modifiers can be used to extend the base class `.button`.
//
// Markup: ../../templates/prototype/partials/components/button.hbs
//
// .button--primary   						- Use this class to indicate that the button is the primary call-to-action. ex. submit, put in basket
// .button--secondary   					- Use this class to indicate that the button is the secondary call-to-action. ex.
// .button--inactive   						- Use this class to indicate that the button is inactive or deactivated.
// .button--primary.button--small			- Makes the button small
// .button--secondary.button--small			- Makes the button small
// .button--primary.button--mini			- Makes the button mini
// .button--secondary.button--mini			- Makes the button mini
// .button--primary.keep-small				- This will prevent the button from width 100% on mobile
// .button--inverted						- This is a rare one!
// .button--transparent						- This is a rare one! Dependant on dark background
// .button--transparent--dark				- This is a rare one! Goes great with ordinary backgrounds.
//
// Style guide: 1.2.1


// Buttons with icon
//
// DOD: not-used
//
// deprecated: **Not used in current version of Domea.** But kept around for easy access, if needed at some point.
// 
// All buttons across the site is built from the same base class. The following modifiers can be used to extend the base class `.button`.
//
// Markup: ../../templates/prototype/partials/components/button-with-icon.hbs
//
// .button--primary									- Green btn with white text, rounded corners
// .button--secondary								- dd
// .button--primary.button--small					- dd
// .button--secondary.button--small					- dd
// .button--secondary.button--small.button--add		- dd
//
// Style guide: 1.2.2


.button {
	transition: all .2s;
	background-color: transparent;
	padding: 0 rem(25);
	display: inline-block;
	overflow: hidden;
	vertical-align: top;
	box-sizing: border-box;
	border: rem(1) solid transparent;
	border-radius: rem(4);
	cursor: pointer;
	outline: none;
	user-select: none;
	text-align: center;
	text-decoration: none;
	white-space: nowrap;
	font: normal em(15)/em(38,15) $roboto;
	text-transform: lowercase;
	&:first-letter{
		text-transform: uppercase;
	}
	@include mq($from: screen-xl) {
		padding: 0 2em;
		&--mini {
			padding: 0 1em;
		}
	}
	&--small {
		font-size: em(14);
		line-height: em(40, 14);
	}
	&--mini {
		font-size: em(11);
		line-height: em(30, 11);
		padding: 0 1em;
	}
	&--wide {
		@include mq($from: screen-sm){
			min-width: rem(175);
		}
	}
	&:hover {
		text-decoration: none;
	}
	&:not(.button--with-icon) {
		>.svg-icon {
			display: none;
		}
	}
	&--bold {
		font-weight: 700;
	}
}

// primary button
.button--primary {
	background-color: $color-green;
	border: 0;
	color: $color-white;
	font-weight: 700;

	&:hover {
		background-color: darken($color-green, 3%);
		color: $color-white;
	}
	&:active {}
	&:visited {
		color: $color-white;
		&:hover {
			color: $color-white;
		}
	}
	>.svg-icon {
		fill: $color-white;
	}
	&.w--border {
		border: rem(2) solid $color-white;

	}
}

//secondary button
$secondary-hover-bg-color: #eaeef0;
$secondary-font-color: #676a6e;
$secondary-border-color: #dde1e4;

.button--secondary {
	background-color: $color-white;
	//border-color: $secondary-border-color;
	color: $color-green;
	text-transform: lowercase;
	font-weight: 700;

	&:first-letter{
		text-transform: uppercase;
	}
	&.button--with-icon {
		text-transform: none; // when svg-icon is present, first-letter is a tag. (ref. first-letter rule above)
	}
	>.svg-icon {
		fill: $color-green;
	}
	&:hover, &:active {
		//background-color: $secondary-hover-bg-color; //darken($color-coolwhite-light, 10%);
		background-color: $color-green;
		color: $color-white;
		>.svg-icon {
			fill: $color-white;
		}
	}
	&:visited {
		color: $color-green;
		&:hover {
			color: $color-white;
		}
	}
	&.w--border {
		border: rem(2) solid $color-green;
	}
}

// white button
.button--white {
	background-color: $color-white;
	border: 0;
	color: $color-black;
	font-weight: 700;

	&:hover {
		background-color: darken($color-white, 3%);
		color: $color-green;
	}
	&:active {
		color: $color-green;
	}
	&:visited {
		color: $color-black;
		&:hover {
			color: $color-green;
		}
	}
	>.svg-icon {
		fill: $color-white;
	}
	&.w--border {
		border: rem(2) solid $color-border;
	}
	&.t--bold {
		font-weight: 700;
	}
}

.button--inactive {
	background-color: $color-grey-light;
	font-weight: normal;
	color: $color-black;
	cursor: default;
}

.button--inverted {
	background-color: transparent;
	color: $color-white;
	border-color: $color-white;
	font-weight: bold;

	&:hover,
	&:active {
		background-color: rgba(0,0,0,.5);
		color: $color-white;
	}
	&:visited {
		color: $color-blue;
		&:hover {
			color: $color-white;
		}
	}
	>.svg-icon {
		fill: $color-white;
	}
}

.button--transparent {
	background-color: transparent;
	color: $color-white;
	border-color: $color-white;
	//border-width: rem(2);
	font-weight: 700;

	&:hover,
	&:active {
		background-color: rgba(0,0,0,.2);
		color: $color-white;
	}
	&:visited {
		color: $color-white;
		&:hover {
			color: $color-white;
		}
	}
	>.svg-icon {
		fill: $color-white;
	}
	&.w--border {
		border: rem(2) solid $color-white;
	}
}

// white button
.button--transparent--dark {
	background-color: transparent;
	border: 0;
	color: $color-black;
	font-weight: 700;

	&:hover {
		background-color: rgba(0,0,0,.1);
		color: $color-green;
	}
	&:focus,
	&:active {
		color: $color-green;
	}
	&:visited {
		color: $color-green;
		&:hover {
			color: $color-green;
		}
	}
	>.svg-icon {
		fill: $color-white;
	}
	&.w--border {
		border: rem(2) solid $color-border;

		&:focus,
		&:active {
			border-color: $color-green;
		}
	}
}

// .button--transparent--dark {
// 	background-color: transparent;
// 	color: $color-black;
// 	border-color: $color-grey;
// 	//border-width: rem(2);
// 	font-weight: normal;

// 	&:hover,
// 	&:active {
// 		background-color: rgba(0,0,0,.2);
// 		color: $color-black;
// 	}
// 	&:visited {
// 		color: $color-black;
// 		&:hover {
// 			color: $color-black;
// 		}
// 	}
// 	>.svg-icon {
// 		fill: $color-black;
// 	}
// 	&.w--border {
// 		border: rem(2) solid $color-grey;
// 	}
// }

// Buttons with icon - Styleguide 3.1.1

.button.button--with-icon {
	padding: 0 em(30) 0 em(15);
	>.svg-icon {
		position: relative;
		top: rem(2);
		margin-right: em(15);
	}
	&.button--add {
		@include mq($from: screen-md) {
			min-width: em(430);
		}

		>.svg-icon {
			margin-right: em(5);
		}
	}
	&.button--icon-right {
		padding: 0 em(15) 0 em(30);
		>.svg-icon {
			top: rem(2);
			margin-left: em(15);
			margin-right: 0;
		}
	}
}
.btn-delete {
	@include reset-button();
	font: normal em(14)/1.2 $roboto;
	color: $color-blue;
	>.circle-icon {
		display: inline-block;
		top: em(3);
		font-size: 1em;
		transform: rotate(45deg);
		background-color: $color-grey-light;
		border-color: $color-grey-light;
		>.svg-icon {
			fill: $color-white;
		}
	}
	&:hover, &:active {
		color: $color-blue-dark;
	}
}

