// Settings

//Global
$base-font-size: 16;
$max-page-width-narrow: 768;
$max-page-width: 1000;
$max-page-width-wide: 1400;
$max-form-width: 585;

// page - when screen is smaller than max-width
$page-gutter: 15;

$modal-small: 512;
$modal-large: 950;


$main-content-margin-left: 60;


// Media queries
$mq-breakpoints: (
	screen-xs-1: 566px, //landscape
	screen-xs: 567px, //landscape
	screen-sm: 768px, //up to tablet portrait
	screen-md: 1024px, //tablet landscape + small desktop
	screen-lg: 1200px, // wrapper min-width
	screen-xl: 1350px, //wide screen
	screen-xxl: 1500px
);

//Grid
//inline grid
$grid-gutter: 25;
$grid-columns: 12;

// floating grid
$column-width: 60px;
$gutter-width: 25px;
$columns: $grid-columns;


// Fonts
$klavika: 'klavika', helvetica, arial, sans-serif;
$roboto: 'roboto', helvetica, arial, sans-serif;
// $roboto-italic: 'robotoitalic', helvetica, arial, sans-serif;
// $roboto-bold: 'robotobold', helvetica, arial, sans-serif;
// $roboto-medium: 'robotomedium', helvetica, arial, sans-serif;
// $roboto-light: 'robotolight', helvetica, arial, sans-serif;

//$sans-fontstack-handwritten: 'felttip', helvetica, arial, sans-serif;
//$serif-fontstack: 'clarendon', times, times new roman, serif;

// Heading
$h1: 46;
$h2: 38;
$h3: 17;
$h1-desktop: 46;
$h2-desktop: 38;
$h3-desktop: 17;
$h1-mobile: 24;
$h2-mobile: 22;
$h3-mobile: 22;

// Spaces
$space-xxsmall: 5;
$space-xsmall: 10;
$space-small: 15;
$space-medium: 20;
$space-large: 25;
$space-xlarge: 30;
$space-xxlarge: 35;

$gutter-mobile: 10;
$gutter-thin: 15;
$gutter-small: 15;
$gutter-normal: 25;
$gutter-large: 35;
$gutter-thick: 50;

$space-section: 35;

// Custom select settings
$select-font-size: 14;


//Z-index
$zindex: (
  modal: 9000, // mobile/tablet menu (everything using overlay)
  overlay: 8000,
  dropdown: 7000, // search dropdown
  header: 6000, // incl. megamenu
  footer: 5000
);

