.list {
	list-style-position: inside;
	&.list--check {
		> li,
		.list__item {
			padding-left: em(30);
			background: url(/static/media/checkmark-thin.svg) no-repeat 0 em(5, 14) transparent;
			font-size: em(16);
			line-height: 1.7;
			list-style-type: none;
			&--green {
				padding-left: em(22);
				background: url(/static/media/checkmark-green.svg) no-repeat 0 em(5, 14) transparent;
			}
			&.list__item--close {
				background: url(/static/media/close.svg) no-repeat 0 em(5, 14) transparent;
			}
		}
	}
	
	&.indent {
		margin-left: em($space-large);
	}
}
li.list__item--check {
	padding-left: em(30);
	background: url(/static/media/checkmark-thin.svg) no-repeat 0 em(5, 14) transparent;
	font-size: em(16);
	line-height: 1.7;
	list-style-type: none !important;
	&--green {
		padding-left: em(22);
		background: url(/static/media/checkmark-green.svg) no-repeat 0 em(5, 14) transparent;
	}
}