// SVG icons - Styleguide 1.7
.svg-icon {
	width: 1em;
	height: 1em;
	fill: $color-black;
}


// Circle icons - Styleguide 1.7.1
.circle-icon {
	width: 1em;
	border: em(1) solid $color-black;
	border-radius: 50%;
	position: relative;
	display: block;
	box-sizing: border-box;
	&.invert {
		border-color: $color-white;
		>.svg-icon {
			fill: $color-white;
		}
	}
	&.no-border {
		border-width: 0;
		border-radius: 0;
	}
	&:before {
		content: '';
		display: block;
		position: relative;
		padding-top: 100%;
		width: 100%;
	}
	>.svg-icon {
		transition: fill .2s;
		width: .6em;
		height: .6em;
		position: absolute;
		top: 52%;
		left: 50%;
		transform: translate(-50%, -50%);
		fill: $color-black;
	}
	.svg-icon--x {
		font-size: 65%;
	}
}

