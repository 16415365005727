//Headings
$article-font-size: 15;
%h1 {
	font: em(32)/1.4 $roboto;
	//text-transform: uppercase;
	@include mq($from:screen-md) {
		font-size: em(42);
	}
}
%h1-klavika {
	font: normal em(32)/1.4 $klavika;
	//text-transform: uppercase;
	@include mq($from:screen-md) {
		font-size: em(48);
	}
}
%h2 {
	font: em(25)/1.4 $roboto;
	//text-transform: uppercase;
	// @include mq($until: screen-lg) {
	// 	text-align: left;
	// }
}
%h2-klavika {
	font: normal em(25)/1.4 $klavika;
}
%h3 {
	font: em(21)/1.4 $roboto;
}
%h3-klavika {
	font: normal em(21)/1.4 $klavika;
}
%h3-small {
	font: normal em(18)/1.4 $roboto;
}
%h4 {
	font: bold em(16)/1.4 $roboto;
	color: $color-black;
	&:not(&.collapse--bottom) {
		margin: 0 0 em($space-xsmall);
	}
}
%h5 {
	font: bold em($article-font-size)/1.4 $roboto;
	&:not(&.collapse--bottom) {
		margin: 0 0 em($space-xsmall, $article-font-size);
	}
}
%paragraph {
	font-family: $roboto;
	font-size: em($article-font-size);
	line-height:1.6;
	font-weight: normal;
	&:not(:last-child) {
		margin-bottom: em($space-small, $article-font-size);
	}
	&.collapse {
		margin: 0;
	}
	&.t--small {
		@extend %t--small;
	}
	>strong {
		font-size: 1em;
	}
	>em {
		font-style: italic;
	}
}

// %handwritten {
// 	font: normal em($article-font-size)/1.4 $sans-fontstack-handwritten;
// }