// Images
//
// Fluent images for responsive purposes. Retina images should in best case be double size.
//
// Markup: ../../templates/styleguide/components/images.hbs
//
// Style guide 1.2

.image-holder {
    &.image-holder--middle {
        font: 0/0 a;
        &:before {
            content: ' ';
            display: inline-block;
            vertical-align: middle;
            height: 100%;
        }
        >img,
        .img {
            font: $base-font-size + px/1.4 $roboto;
            display: inline-block;
        }
    }
}

img {
    max-width: 100%;
    font-style: italic;
    vertical-align: middle;
}

.gm-style img,
.map-canvas img[width],
.map-canvasimg[height] {
    max-width: none;
}
